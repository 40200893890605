import { userType } from '../action/types';

const initialState = {
    isLogin: false,
    isReadOnly: false,
    user: {},
}

const userReducer = (state = initialState, action) => {
    
    const { type, payload } = action;
    
    switch (type) {
        case userType.LOGIN:
            return {
                ...state,
                isLogin: true,
                user: payload,
            }
        case userType.LOGOUT:
            return {
                ...state,
                isLogin: false,
                isReadOnly: false,
                user: {},
            }
        case userType.READONLY:
            return {
                ...state,
                isReadOnly: true,
            }
        default:
            return state;
    }
}

export default userReducer;