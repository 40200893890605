import React from "react";
import { Menu } from "antd";
import * as Icon from "react-feather";
import userAction from '../../redux/action/user-action';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

const HeaderMenu = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const isAuthenticated = useSelector(state=>state.userReducer.isLogin);

    const onLogout = async () => {
        dispatch(userAction.logout());
        history.push('/');
    }

    const onLogin = async () => {
        history.push('/login');
    }

    const onChangePassword = async () => {
        history.push('/change-password');
    }

    return (
        <Menu>
            {
                isAuthenticated ?
                <>
                    <Menu.Item key="changePassword" onClick={onChangePassword}>
                        <span target="_blank" rel="noopener noreferrer">
                            <Icon.Key size='14' className="transform-nmu me-1 color-primary" />Change Password
                        </span>
                    </Menu.Item>
                    <Menu.Item key="logout" onClick={onLogout}>
                        <span target="_blank" rel="noopener noreferrer">
                            <Icon.LogOut size='14' className="transform-nmu me-1 color-primary" />Logout
                        </span>
                    </Menu.Item>
                </>
                :
                <Menu.Item key="login" onClick={onLogin}>
                    <span target="_blank" rel="noopener noreferrer">
                        <Icon.LogIn size='14' className="transform-nmu me-1 color-primary" />Login
                    </span>
                </Menu.Item>
            }
        </Menu>
    )
}

export default HeaderMenu;