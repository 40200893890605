import styles from '../../styles/loader.module.css';
import React from "react";
import { useSelector } from 'react-redux';
import {
    Spin
} from 'antd';

const AppLoader = () => {

    const enable = useSelector(state=>state.appLoaderReducer.isLoader);

    if (!enable) {
        return null;
    }

    return (
        <div id="loader" className={styles.loadingWrapper}>
            <div className={styles.loadingBg}></div>
            <Spin className={styles.loaderSpin} size="large" />
        </div>
    );

}

export default AppLoader;