import React from 'react';
import SearchBrand from './search';

const Brand = () => {

    return (
        <SearchBrand />
    )
}

export default Brand;