import StorageUtils from './storage-session-utils';
// import { API_KEY } from '../config/app-config';

const get = async (url, payload = {}) => {
    try {
        const headers = getHeaders();
        const option = {
            method: 'GET',
            headers: headers,
            body: JSON.stringify(payload),
        }

        const response = await fetch(url, option);

        if (!response.ok) {
            if (response.status === 403) {
                throw { message: "บัญชีของท่านไม่สามารถใช้งานได้ในขณะนี้ กรุณาลงชื่อเข้าใช้ใหม่อีกครั้ง", code: response.status };
            } else {
                const error = await response.json();
                throw error;
            }
        }

        const data = await response.json();

        return data;

    } catch (err) {
        errorInterceptor(err);
    }
}

const post = async (url, payload = {}) => {
    try {
        const headers = getHeaders();
        const option = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(payload),
        }

        const response = await fetch(url, option);

        if (!response.ok) {
            if (response.status === 403) {
                throw { message: "บัญชีของท่านไม่สามารถใช้งานได้ในขณะนี้ กรุณาลงชื่อเข้าใช้ใหม่อีกครั้ง", code: response.status };
            } else {
                const error = await response.json();
                throw error;
            }
        }

        const data = await response.json();

        return data;

    } catch (err) {
        errorInterceptor(err);
    }
}

const put = async (url, payload = {}) => {
    try {
        const headers = getHeaders();
        const option = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(payload),
        }

        const response = await fetch(url, option);

        if (!response.ok) {
            if (response.status === 403) {
                throw { message: "บัญชีของท่านไม่สามารถใช้งานได้ในขณะนี้ กรุณาลงชื่อเข้าใช้ใหม่อีกครั้ง", code: response.status };
            } else {
                const error = await response.json();
                throw error;
            }

        }

        const data = await response.json();

        return data;

    } catch (err) {
        errorInterceptor(err);
    }
}

const patch = async (url, payload = {}) => {
    try {
        const headers = getHeaders();
        const option = {
            method: 'PATCH',
            headers: headers,
            body: JSON.stringify(payload),
        }

        const response = await fetch(url, option);

        if (!response.ok) {
            if (response.status === 403) {
                throw { message: "บัญชีของท่านไม่สามารถใช้งานได้ในขณะนี้ กรุณาลงชื่อเข้าใช้ใหม่อีกครั้ง", code: response.status };
            } else {
                const error = await response.json();
                throw error;
            }

        }

        const data = await response.json();

        return data;

    } catch (err) {
        errorInterceptor(err);
    }
}

const del = async (url, payload = {}) => {
    try {
        const headers = getHeaders();
        const option = {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify(payload),
        }

        const response = await fetch(url, option);

        if (!response.ok) {
            if (response.status === 403) {
                throw { message: "บัญชีของท่านไม่สามารถใช้งานได้ในขณะนี้ กรุณาลงชื่อเข้าใช้ใหม่อีกครั้ง", code: response.status };
            } else {
                const error = await response.json();
                throw error;
            }

        }

        const data = await response.json();

        return data;

    } catch (err) {
        errorInterceptor(err);
    }
}

const postFormData = async (url, formData) => {
    try {
        const headers = getFormHeaders();
        const option = {
            method: 'POST',
            headers,
            body: formData
        }

        const response = await fetch(url, option);

        if (!response.ok) {
            if (response.status === 403) {
                throw { message: "บัญชีของท่านไม่สามารถใช้งานได้ในขณะนี้ กรุณาลงชื่อเข้าใช้ใหม่อีกครั้ง", code: response.status };
            } else {
                const error = await response.json();
                throw error;
            }

        }

        const data = await response.json();

        return data;

    } catch (err) {
        errorInterceptor(err);
    }
}

const getHeaders = () => {
    const token = StorageUtils.getToken();
    const headers = {
        'content-type': "application/json",
    }
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }
    return headers;
}

const getFormHeaders = () => {
    const token = StorageUtils.getToken();
    const headers = {
        'content-type': "multipart/form-data",
        // 'content-type': "application/json",
        // 'api_key': API_KEY
    }
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }
    return headers;
}

const errorInterceptor = (err) => {
    if (err instanceof TypeError) {
        throw "Something went wrong.(Interface api fail)";
    } else if (err instanceof Error) {
        throw `Something went wrong.(${err.message})`;
    } else {
        throw err;
    }
}

const httpUtils = {
    get,
    post,
    put,
    patch,
    del,
    postFormData
}

export default httpUtils;