import React from 'react';
import { Table } from 'antd';


const DataTable = (props) => {
    return (
        <Table {...props} />
    )

}

export default DataTable;