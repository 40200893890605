import React from 'react';
import SearchOrder from './search';

const Order = () => {

    return (
        <SearchOrder />
    )
}

export default Order;