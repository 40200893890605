import { salesType } from "./types";

const order = (obj) => {
    return {
        type: salesType.ORDER,
        payload: obj,
    }
}

const cart = (obj) => {
    return {
        type: salesType.CART,
        payload: obj,
    }
}

const constants = (obj) => {
    return {
        type: salesType.CONSTANTS,
        payload: obj,
    }
}

const filter = (obj) => {
    return {
        type: salesType.FILTER,
        payload: obj,
    }
}

const clear = () => {
    return {
        type: salesType.CLEAR,
    }
}


const salesAction = {
    order,
    cart,
    constants,
    filter,
    clear,
}

export default salesAction;