import React from 'react';
import SearchModel from './search';

const Model = () => {

    return (
        <SearchModel />
    )
}

export default Model;