import { appLoaderType } from '../action/types';

const initialState = {
    isLoader: false
}

const appLoaderReducer = (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case appLoaderType.DISABLE:
            return {
                ...state,
                isLoader: false
            }
        case appLoaderType.ENABLE:
            return {
                ...state,
                isLoader: true
            }
        default:
            return state;
    }
}

export default appLoaderReducer;