import React, { useState } from 'react';
import { Card, Input, Button, Form, Row, Col, Space } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import loaderAction from '../../redux/action/app-loader-action';
import userAction from '../../redux/action/user-action';
import notifyUtils from "../../utils/notify-utils";
import commonService from '../../services/common-service';
import * as Icon from "react-feather";
import storageUtils from '../../utils/storage-session-utils';
import { SaveOutlined, CloseOutlined, RollbackOutlined } from '@ant-design/icons';

const ChangePassword = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector(state=>state.userReducer.user);
    const [form] = Form.useForm();
    const [data, setData] = useState({username:null,oldPassword:null,newPassword:null,confirmPassword:null});

    const onChangeValue = (event) => {
        const {name, value} = event.target;
        setData({...data, [name]: value});
    };

    const changePassword = async (values) => {
        try {
            dispatch(loaderAction.enable());

            const res = await commonService.changePassword(values);
            dispatch(userAction.login(res.response));

            notifyUtils.success("Success", "Change password successful.");
            storageUtils.setToken(res.response.token);
            history.push("/dashboard");

            dispatch(loaderAction.disable());
        } catch (err) {
            dispatch(loaderAction.disable());
            notifyUtils.error(err);
        }
    };

    const onFinish = (values) => {
        changePassword(values);
    }

    const onResetFields = () => {
        form.resetFields();
    }
    
    const onBack = () => {
        history.goBack(); 
    }

    return (
        <Card>
            <Row> 
                <Col span={12}><h5>Change Password</h5></Col>
            </Row>
            <Form name="basic" layout="vertical" className="my-3"
                form={form}
                onFinish={onFinish}
                initialValues={{ username: user.username }}
                labelCol={{ span: 24, }} wrapperCol={{ span: 24, }}
            >
                <Form.Item
                    label={<div className="d-flex"> <Icon.User className="me-1 color-primary" size={18} /> Username </div>}
                    name="username"
                >
                    <Input name="username" className="w-100" value={data.username} onChange={onChangeValue} disabled />
                </Form.Item>
                <Form.Item
                    label={<div className="d-flex"> <Icon.Lock className="me-1 color-primary" size={18} /> Old Password </div>}
                    name="oldPassword"
                    rules={[
                        {
                            required: true,
                            message: "Please input old password!",
                        },
                    ]}
                >
                    <Input.Password name="oldPassword" className="w-100" value={data.oldPassword} onChange={onChangeValue} />
                </Form.Item>
                <Form.Item
                    label={<div className="d-flex"> <Icon.Lock className="me-1 color-primary" size={18} /> New Password </div>}
                    name="newPassword"
                    rules={[
                        {
                            required: true,
                            message: "Please input new password!",
                        },
                    ]}
                >
                    <Input.Password name="newPassword" className="w-100" value={data.newPassword} onChange={onChangeValue} />
                </Form.Item>
                <Form.Item
                    label={<div className="d-flex"> <Icon.Lock className="me-1 color-primary" size={18} /> Confirm Password </div>}
                    name="confirmPassword"
                    className="mb-20"
                    dependencies={["newPassword"]}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: "Please input your confirm password!",
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue("newPassword") === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error("The two passwords that you entered do not match!"));
                            },
                        }),
                    ]}
                >
                    <Input.Password name="confirmPassword" className="w-100" value={data.confirmPassword} onChange={onChangeValue} />
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        span: 24,
                    }}
                >
                    <Space>
                        <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>Save</Button>
                        <Button type="primary" onClick={onResetFields} icon={<CloseOutlined />}>Cancel</Button>
                        <Button type="primary" onClick={onBack} icon={<RollbackOutlined />}>Back</Button>
                    </Space>
                </Form.Item>
            </Form>
        </Card>
    )
}

export default ChangePassword;