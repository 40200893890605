import React, { memo, useState } from 'react';
import { Layout, BackTop } from 'antd';
import { useSelector } from 'react-redux';
import Navbar from '../navbar';
import Header from '../header';
import Footer from '../footer';

const { Content } = Layout;

const MainContainer = (props) => {

    const [collapsed, setCollapsed] = useState(false);
    const toggle = () => setCollapsed(v => !v);
    const isAuthenticated = useSelector(state=>state.userReducer.isLogin);

    return (
        <>
            <Layout>
                {
                    isAuthenticated ? <Navbar collapsed={collapsed} /> : null
                }
                
                <Layout className="site-layout">
                    <Header collapsed={collapsed} toggle={toggle} />
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '24px 16px',
                            padding: 24,
                            minHeight: 280,
                        }}
                    >
                        {props.children}
                    </Content>
                    {
                        !isAuthenticated ? <Footer /> : null
                    }
                </Layout>
            </Layout>
            
            <BackTop />
        </>
    );

}

export default memo(MainContainer);