import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Input, Button, Divider, Collapse, Select, Space, Popconfirm } from 'antd';
import * as Icon from "react-feather";
import DataTable from '../../components/data-table';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { PlusOutlined, EditOutlined, DeleteOutlined, SearchOutlined, ClearOutlined } from '@ant-design/icons';
import loaderAction from '../../redux/action/app-loader-action';
import notifyUtils from "../../utils/notify-utils";
import masterService from "../../services/master-service";
import { RECORD_STATUS } from "../../constants/app-constants"
import masterAction from '../../redux/action/master-action';

const { Panel } = Collapse;

const SearchBrand = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const [condition, setCondition] = useState({});
    const [brands, setBrands] = useState([]);
    const isReadOnly = useSelector(state=>state.userReducer.isReadOnly);
    
    const onChangeValue = (event) => {
        const {name, value} = event.target;
        setCondition({...condition, [name]: value});
    };

    const onChangeStatus = (value) => {
        setCondition({...condition, status: value});
    };

    useEffect(() => {
        onSearch();
    }, []);

    const searchBrand = async (condition) => {
        try {
            dispatch(loaderAction.enable());

            const res = await masterService.searchBrand(condition);
            setBrands(res.response);

            dispatch(loaderAction.disable());
        } catch (err) {
            dispatch(loaderAction.disable());
            notifyUtils.error(err);
        }
    }

    const deleteBrand = async (condition) => {
        try {
            dispatch(loaderAction.enable());

            await masterService.deleteBrand(condition);

            notifyUtils.success("Success", "Delete Brand successful.");

            onSearch();

            dispatch(loaderAction.disable());
        } catch (err) {
            dispatch(loaderAction.disable());
            notifyUtils.error(err);
        }
    }

    const columns = [
        {
            title: 'Brand Name',
            dataIndex: 'name',
            key: 'name',
            width: 300
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            render: (_, record) => {
                return RECORD_STATUS.find(item => item.value === record.status)?.label;
            }
        },
        {
            dataIndex: 'view',
            width: 100,
            render: (_, record) => {
                return <Space>
                            <Button type="primary" shape="circle" size="large" onClick={() => onView(record)} icon={<EditOutlined />} />
                            <Popconfirm title="sure to delete?" onConfirm={() => onDelete(record)} disabled={isReadOnly}>
                                <Button type="primary" shape="circle" size="large" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Space>
            }
        }
    ];

    const onSearch = () => {
        searchBrand(condition);
    };

    const onClear = () => {
        setCondition({});
    };

    const onView = (record) => {
        const newData = {
            isAdd: false,
            data: record,
        }
        dispatch(masterAction.brand(newData));
        history.push("/brand-view");
    }
    
    const onAdd = () => {
        const newData = {
            isAdd: true,
            data: {
                name: null,
                status: "0",
            },
        }
        dispatch(masterAction.brand(newData));
        history.push("/brand-view");
    }

    const onDelete = (record) => {
        deleteBrand({id:record.id});
    }

    const title = () => {
        return (
            <Space>
                <h5>Total Records : {brands.length}</h5>
                <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={onAdd} />
            </Space>
        );
    }

    return (
        <div>
            <h2 className="color-primary-title">Brand</h2>
            <Divider className="mt-2 mb-4" />
            <Card className="mt-4" bordered={false}>
                <Collapse className="mb-5" defaultActiveKey={['1']}>
                    <Panel header={<> <Icon.Search className="me-2" size={14} /> Filter</>} key="1">
                        <Form layout="vertical">
                            <Row gutter={20}>
                                <Col span={12} xs={24} sm={24} md={12}>
                                    <Form.Item label="Brand Name:">
                                        <Input name="name" onChange={onChangeValue} value={condition.name} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} xs={24} sm={24} md={12}>
                                    <Form.Item label="Status:">
                                        <Select name="status" onChange={onChangeStatus} value={condition.status}>
                                            {RECORD_STATUS.map((item)=>{
                                                return <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col span={12} xs={24} sm={24} md={12}>
                                    <Form.Item>
                                        <Space>
                                            <Button type="primary" onClick={onSearch} icon={<SearchOutlined />}>Submit</Button>
                                            <Button onClick={onClear} icon={<ClearOutlined />}>Clear</Button>
                                        </Space>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>

                <DataTable scroll={{ x: 'max-content' }} columns={columns} dataSource={brands} title={title} rowKey="id" />
            </Card>
        </div>
    );

}

export default SearchBrand;