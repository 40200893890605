import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import TemplateRoute from './template-route';

import Login from '../pages/login';
import ChangePassword from '../pages/change-password';

// master
import Brand from '../pages/brand';
import BrandView from '../pages/brand/view';
import Color from '../pages/color';
import ColorView from '../pages/color/view';
import Diameter from '../pages/diameter';
import DiameterView from '../pages/diameter/view';
import Model from '../pages/model';
import ModelView from '../pages/model/view';
import Power from '../pages/power';
import PowerView from '../pages/power/view';
import Product from '../pages/product';
import ProductView from '../pages/product/view';

//
import Home from '../pages/home';
import Dashboard from '../pages/dashboard';
import Order from '../pages/order';
import OrderView from '../pages/order/view';
import Cart from '../pages/cart';
import Carousel from '../pages/carousel';

//loader
import AppLoader from '../components/loader';

const AppRouteContainer = () => {
    return (
        <>
            <BrowserRouter>
                <Switch>
                    {/* <Route exact path="/login" component={Login} /> */}
                    <TemplateRoute exact path="/login" component={Login} />
                    <TemplateRoute exact path="/" component={Home} />
                    <TemplateRoute exact path="/cart" component={Cart} />

                    <TemplateRoute exact path="/change-password" isAdmin={true} component={ChangePassword} />
                    <TemplateRoute exact path="/dashboard" isAdmin={true} component={Dashboard} />
                    <TemplateRoute exact path="/carousel" isAdmin={true} component={Carousel} />
                    <TemplateRoute exact path="/order" isAdmin={true} component={Order} />
                    <TemplateRoute exact path="/order-view" isAdmin={true} component={OrderView} />
                    <TemplateRoute exact path="/brand" isAdmin={true} component={Brand} />
                    <TemplateRoute exact path="/brand-view" isAdmin={true} component={BrandView} />
                    <TemplateRoute exact path="/color" isAdmin={true} component={Color} />
                    <TemplateRoute exact path="/color-view" isAdmin={true} component={ColorView} />
                    <TemplateRoute exact path="/diameter" isAdmin={true} component={Diameter} />
                    <TemplateRoute exact path="/diameter-view" isAdmin={true} component={DiameterView} />
                    <TemplateRoute exact path="/model" isAdmin={true} component={Model} />
                    <TemplateRoute exact path="/model-view" isAdmin={true} component={ModelView} />
                    <TemplateRoute exact path="/power" isAdmin={true} component={Power} />
                    <TemplateRoute exact path="/power-view" isAdmin={true} component={PowerView} />
                    <TemplateRoute exact path="/product" isAdmin={true} component={Product} />
                    <TemplateRoute exact path="/product-view" isAdmin={true} component={ProductView} />
                </Switch>
            </BrowserRouter>
            <AppLoader />
        </>
    );
}

export default AppRouteContainer;