import { createStore, applyMiddleware } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import createSagaMiddleware from 'redux-saga';

import rootReducer from '../redux/reducer';
import rootSaga from '../redux/saga';

const persistConfig = {
    key: 'luxzyWsStore',
    storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();


const configStore = (onCompletion) => {

    const store = createStore(persistedReducer, applyMiddleware(sagaMiddleware));
    let persistor = persistStore(store, null, onCompletion);

    sagaMiddleware.run(rootSaga);

    return { store, persistor };
}


export default configStore;