import React, { useState, useEffect } from 'react';
import { Carousel, Image } from 'antd';
import commonUtils from "../../utils/common-utils";
import ProductList from './product-list';
import commonService from '../../services/common-service';
import loaderAction from '../../redux/action/app-loader-action';
import notifyUtils from "../../utils/notify-utils";
import { useDispatch } from 'react-redux';

const Home = () => {

    const dispatch = useDispatch();
    const [images, setImages] = useState([]);

    useEffect(() => {
        getCarousels();
    }, []);

    const getCarousels = async (condition) => {
        try {
            dispatch(loaderAction.enable());

            const res = await commonService.getCarousels(condition);
            setImages(res.response);

            dispatch(loaderAction.disable());
        } catch (err) {
            dispatch(loaderAction.disable());
            notifyUtils.error(err);
        }
    }

    return (
        <>
            <Carousel autoplay effect='fade'>
                {
                    images.map(img => {
                        return <Image key={img} preview={false} width='100%' src={commonUtils.getCarouselImage(img)} />
                    })
                }
            </Carousel>
            <ProductList />
        </>
    )
}

export default Home;