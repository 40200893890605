import { PRODUCT_PATH, IMAGE_PATH, CAROUSEL_PATH } from '../config/app-config';

const getImage = (imgName) => {
    return IMAGE_PATH + '/' + imgName;
}

const getProductImage = (imgName) => {
    return PRODUCT_PATH + '/' + imgName;
}

const getCarouselImage = (imgName) => {
    return CAROUSEL_PATH + '/' + imgName;
}

const random = (length = 8) => {
    return Math.random().toString(16).substring(2, length);
}

const commonUtils = {
    getImage,
    getProductImage,
    getCarouselImage,
    random,
}

export default commonUtils;