import React, { useState, useEffect } from 'react';
import { Card, Input, Button, Form, Row, Col, Space, Select, Image, InputNumber, Typography } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import loaderAction from '../../redux/action/app-loader-action';
import notifyUtils from "../../utils/notify-utils";
import salesService from "../../services/sales-service";
import * as Icon from "react-feather";
import { ORDER_STATUS, RECORD_STATUS, PRODUCT_TYPE_OPTION } from "../../constants/app-constants"
import DataTable from '../../components/data-table';
import commonUtils from '../../utils/common-utils';
import { SaveOutlined, CloseOutlined, RollbackOutlined } from '@ant-design/icons';

const ViewOrder = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const dataSelector = useSelector(state=>state.salesReducer.order);
    const [data, setData] = useState(dataSelector.data);
    const [details, setDetails] = useState(data.details);
    const [form] = Form.useForm();
    const isReadOnly = useSelector(state=>state.userReducer.isReadOnly);

    useEffect(() => {
    }, []);

    useEffect(() => {
        setData({...data, details: details});
    }, [details]);

    const onChangeValue = (event) => {
        const {name, value} = event.target;
        setData({...data, [name]: value});
    };

    const onChangeStatus = (value) => {
        setData({...data, status: value});
    };

    const addOrder = async (values) => {
        try {
            dispatch(loaderAction.enable());

            await salesService.createOrder(values);
            onBack();

            notifyUtils.success("Success", "Create Order successful.");

            dispatch(loaderAction.disable());
        } catch (err) {
            dispatch(loaderAction.disable());
            notifyUtils.error(err);
        }
    };

    const updateOrder = async (values) => {
        try {
            dispatch(loaderAction.enable());

            await salesService.updateOrder(values);
            onBack();

            notifyUtils.success("Success", "Update Order successful.");

            dispatch(loaderAction.disable());
        } catch (err) {
            dispatch(loaderAction.disable());
            notifyUtils.error(err);
        }
    };

    const onFinish = (values) => {
        if (dataSelector.isAdd) {
            addOrder(values);
        }
        else {
            const newValues = {...values};
            newValues.details = details;
            updateOrder(newValues);
        }
    };

    const onResetFields = () => {
        form.resetFields();
    }

    const onBack = () => {
        history.goBack(); 
    }

    const title = () => {
        return (
            <Space>
                <h5>Total Records : {data.details.length}</h5>
            </Space>
        );
    }

    const onChangePower = (value, index) => {
        const newDetails = [...details];
        newDetails[index].powerId = value;
        setDetails(newDetails);
    }

    const onChangeQuantity = (value, index) => {
        const newDetails = [...details];
        newDetails[index].quantity = value;
        setDetails(newDetails);
    }

    const onChangeDetailStatus = (value, index) => {
        const newDetails = [...details];
        newDetails[index].status = value;
        setDetails(newDetails);
    }

    const columns = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            width: 70,
            render: (_, record) => {
                return <Image width={70} src={commonUtils.getProductImage(record.product.imgName)} />
            }
        },
        {
            title: 'Product Name',
            dataIndex: 'product',
            key: 'product',
            width: 350,
            render: (_, record) => {
                return record.product.name;
            }
        },
        {
            title: 'Power',
            dataIndex: 'name',
            key: 'name',
            width: 100,
            render: (_, record, index) => {
                return record.powerId === null ?
                        PRODUCT_TYPE_OPTION[0].label
                        : <Select style={{width: "150px"}} name="status" placeholder="please select" disabled={isReadOnly}
                            value={record.powerId} onChange={(value)=>onChangePower(value, index)}>
                            {record.product.powers.map((p)=>{
                                return <Select.Option key={p.power.value} value={p.power.value}>{p.power.label}</Select.Option>
                            })}
                        </Select>
            }
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 100,
            render: (_, record, index) => {
                return <InputNumber defaultValue={0} min={0} max={99} value={record.quantity} onChange={(value)=>onChangeQuantity(value, index)} disabled={isReadOnly} />
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            render: (_, record, index) => {
                return <Select style={{width: "150px"}} name="status" placeholder="please select" disabled={isReadOnly}
                            value={record.status} onChange={(value)=>onChangeDetailStatus(value, index)}>
                            {RECORD_STATUS.map((p)=>{
                                return <Select.Option key={p.value} value={p.value}>{p.label}</Select.Option>
                            })}
                        </Select>
            }
        },
    ];

    return (
        <Card>
            <Row> 
                <Col span={12} xs={24} sm={24} md={12}>
                    <Typography.Title level={5}>
                        {dataSelector.isAdd ? "Add" : "Update"} Order
                    </Typography.Title>
                </Col>
            </Row>
            <Form name="basic" layout="vertical" className="my-3"
                form={form}
                onFinish={onFinish}
                initialValues={data}
                labelCol={{ span: 12, }} wrapperCol={{ span: 23, }}
            >
                <Row>
                    <Col span={12}>
                        <Form.Item
                            label={<div className="d-flex"> <Icon.Archive className="me-1 color-primary" size={18} /> Order # </div>}
                            name="id"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input id!",
                                },
                            ]}
                        >
                            <Input name="id" className="w-100" value={data.id} onChange={onChangeValue} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={<div className="d-flex"> <Icon.Archive className="me-1 color-primary" size={18} /> Status </div>}
                            name="status"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input order details!",
                                },
                            ]}
                        >
                            <Select name="status" value={data.status} onChange={onChangeStatus} disabled={isReadOnly}>
                                {ORDER_STATUS.map((item)=>{
                                    return <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={<div className="d-flex"> <Icon.Archive className="me-1 color-primary" size={18} /> Name </div>}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input name!",
                                },
                            ]}
                        >
                            <Input name="name" className="w-100" value={data.name} onChange={onChangeValue} disabled={isReadOnly} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={<div className="d-flex"> <Icon.Archive className="me-1 color-primary" size={18} /> Mobile </div>}
                            name="mobile"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input mobile!",
                                },
                            ]}
                        >
                            <Input name="mobile" className="w-100" value={data.mobile} onChange={onChangeValue} disabled={isReadOnly} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={<div className="d-flex"> <Icon.Archive className="me-1 color-primary" size={18} /> Email </div>}
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input email!",
                                },
                                {
                                    type: 'email',
                                    message: "Please input email correct format!",
                                }
                            ]}
                        >
                            <Input name="email" className="w-100" value={data.email} onChange={onChangeValue} disabled={isReadOnly} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={<div className="d-flex"> <Icon.Archive className="me-1 color-primary" size={18} /> Line ID </div>}
                            name="lineId"
                        >
                            <Input name="lineId" className="w-100" value={data.lineId} onChange={onChangeValue} disabled={isReadOnly} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={<div className="d-flex"> <Icon.Archive className="me-1 color-primary" size={18} /> Shipping Address </div>}
                            name="shipTo"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input shipping address!",
                                },
                            ]}
                        >
                            <Input.TextArea name="shipTo" rows={3} className="w-100" value={data.shipTo} onChange={onChangeValue} disabled={isReadOnly} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={<div className="d-flex"> <Icon.Archive className="me-1 color-primary" size={18} /> Order Details </div>}
                            name="detail"
                        >
                            <Input.TextArea name="detail" rows={3} className="w-100" value={data.detail} onChange={onChangeValue} disabled={isReadOnly} />
                        </Form.Item>
                    </Col>
                </Row>

                <DataTable scroll={{ x: 'max-content' }} columns={columns} dataSource={details} title={title} rowKey="id" />

                <Form.Item
                    wrapperCol={{
                        span: 24,
                    }}
                >
                    <Space>
                        <Button type="primary" htmlType="submit" disabled={isReadOnly} icon={<SaveOutlined />}>Save</Button>
                        <Button type="primary" onClick={onResetFields} icon={<CloseOutlined />}>Cancel</Button>
                        <Button type="primary" onClick={onBack} icon={<RollbackOutlined />}>Back</Button>
                    </Space>
                </Form.Item>
            </Form>
        </Card>
    )

}

export default ViewOrder;