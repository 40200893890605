import { userType } from "./types";

const login = (obj) => {
    return {
        type: userType.LOGIN,
        payload: obj,
    }
}

const logout = () => {
    return {
        type: userType.LOGOUT,
    }
}

const readOnly = () => {
    return {
        type: userType.READONLY,
    }
}

const userAction = {
    login,
    logout,
    readOnly,
}

export default userAction;