import moment from 'moment';
import { DISPLAY_DATE_FORMAT, DATE_FORMAT, NUMBER_DECIMAL } from "../config/app-config";

const number = (number) => {
    if (number) {
        return Number(number).toLocaleString(undefined, { minimumFractionDigits: NUMBER_DECIMAL, maximumFractionDigits: NUMBER_DECIMAL });
    }
    return number;
}

const numberCustom = (number, decimal) => {
    if (number) {
        return number.toLocaleString(undefined, { minimumFractionDigits: decimal, maximumFractionDigits: decimal });
    }
    return number;
}

const date = (dateString) => {
    if (dateString) {
        return moment(dateString, DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
    }
    return dateString;
}

const yesNo = (value) => {
    if (value) {
        return value === "Y" ? "Yes" : "No";
    }
    return value;
}

const formatUtils = {
    number,
    numberCustom,
    date,
    yesNo,
}

export default formatUtils;