import { constantsType } from "./types";

const brands = (obj) => {
    return {
        type: constantsType.BRANDS,
        payload: obj,
    }
}

const products = (obj) => {
    return {
        type: constantsType.PRODUCTS,
        payload: obj,
    }
}

const constantsAction = {
    brands,
    products
}

export default constantsAction;