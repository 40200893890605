import React, { useState, useEffect } from 'react';
import { Space, Button, InputNumber, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import salesAction from '../../redux/action/sales-action';
import { PRODUCT_TYPE } from '../../constants/app-constants'
import commonUtils from '../../utils/common-utils';

const ProductSelect = (props) => {

    const dispatch = useDispatch();
    const cart = useSelector(state=>state.salesReducer.cart);
    const [product] = useState(props.product);
    const [data, setData] = useState(props.data);
    const [updateObj, setUpdateObj] = useState();

    useEffect(() => {
    }, []);

    useEffect(() => {
        const cartz = [...cart];
        if (updateObj !== undefined) {
            const index = cartz.findIndex(item => item.key === updateObj.data.key);
            if ("add" === updateObj.action) {
                cartz.push({...updateObj.data});
            }
            else if ("update" === updateObj.action) {
                if (index >= 0) {
                    cartz[index] = {...updateObj.data};
                }
                else {
                    cartz.push({...updateObj.data});
                }
            }
            else if ("delete" === updateObj.action) {
                cartz.splice(index, 1);
            }
            dispatch(salesAction.cart(cartz));
        }
    }, [updateObj]);

    const onChangePower = (value, index) => {
        const newData = [...data];
        newData[index].powerId = value;
        setData(newData);
        setUpdateObj({action:'update',data:newData[index]});
    }
    
    const onPlus = (index) => {
        const newData = [...data];
        newData[index].quantity++;
        setData(newData);
        setUpdateObj({action:'update',data:newData[index]});
    }
    
    const onMinus = (index) => {
        const newData = [...data];
        if (newData[index].quantity > 0) {
            newData[index].quantity--;
            setData(newData);
            setUpdateObj({action:'update',data:newData[index]});
        }
    }

    const onChangeQuantity = (value, index) => {
        const newData = [...data];
        newData[index].quantity = value;
        setData(newData);
        setUpdateObj({action:'update',data:newData[index]});
    }

    const onBlurQuantity = (event, index) => {
        const { name, value } = event.target;
        const newData = [...data];
        newData[index].quantity = value;
        setData(newData);
        setUpdateObj({action:'update',data:newData[index]});
    }

    const onAddRow = () => {
        const newData = [...data];
        const newObj = {
            key: commonUtils.random(16),
            type: product.type,
            productId: product.id,
            name: product.name,
            quantity: 0,
            powerId: null,
            product: product,
            status: product.status,
        }
        newData.push(newObj);
        setData(newData);
        setUpdateObj({action:'add',data:newObj});
    }
    
    const onDeleteRow = (index) => {
        const newData = [...data];
        const newObj = {...newData[index]};
        newData.splice(index, 1);
        setData(newData);
        setUpdateObj({action:'delete',data:newObj});
    }

    const checkPowerDisabled = (product, value) => {
        const index = cart.findIndex(item => item.productId === product.productId && item.powerId === value);
        return index >= 0;
    }

    return (
        data.map((item, index) => {
            return <Space key={item.key}>
                        {
                            PRODUCT_TYPE.POWER === product.type ?
                            <Select style={{width: "150px"}} name="status" placeholder="เลือกค่าสายตา" value={item.powerId} onChange={(value)=>onChangePower(value, index)}>
                                {product.powers.map((p)=>{
                                    return <Select.Option key={p.value} value={p.value} disabled={checkPowerDisabled(item, p.value)}>{p.label}</Select.Option>
                                })}
                            </Select>
                            : null
                        }
                        <Button type='primary' shape='circle' size="large" icon={<MinusOutlined />} onClick={()=>onMinus(index)} />
                        <InputNumber defaultValue={0} min={0} value={item.quantity} onBlur={(e)=> onBlurQuantity(e, index)} />
                        <Button type='primary' shape='circle' size="large" icon={<PlusOutlined />} onClick={()=>onPlus(index)} />
                        {
                            PRODUCT_TYPE.POWER === product.type ?
                                index === 0 ?
                                <Button type='primary' icon={<PlusOutlined />} onClick={onAddRow}>Add Row</Button>
                                : <Button type='primary' icon={<PlusOutlined />} onClick={()=>onDeleteRow(index)}>Delete Row</Button>
                            : null
                        }
                        
                    </Space>
        })
    )

}

export default ProductSelect;