import { salesType } from '../action/types';

const initialState = {
    order: {},
    cart: [],
    constants: {brands:[], models:[], colors:[], types:[], powers:[], diameters:[]},
    defaultFilter: {brands:[], models:[], colors:[], types:[], powers:[], diameters:[]},
    filter: {brands:[], models:[], colors:[], types:[], powers:[], diameters:[]},
}

const salesReducer = (state = initialState, action) => {
    
    const { type, payload } = action;
    
    switch (type) {
        case salesType.ORDER:
            return {
                ...state,
                order: payload,
            }
        case salesType.CART:
            return {
                ...state,
                cart: payload,
            }
        case salesType.CONSTANTS:
            return {
                ...state,
                constants: payload,
            }
        case salesType.FILTER:
            return {
                ...state,
                filter: payload,
            }
        case salesType.CLEAR:
            return initialState
        default:
            return state;
    }
}

export default salesReducer;