import salesApi from "../api/sales-api";
import { RESPONSE_STATUS } from '../constants/app-constants';

// Order
const searchOrder = async (condition) => {
    const resp = await salesApi.searchOrder(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const viewOrder = async (condition) => {
    const resp = await salesApi.getOrder(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const createOrder = async (condition) => {
    const resp = await salesApi.createOrder(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const updateOrder = async (condition) => {
    const resp = await salesApi.updateOrder(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const deleteOrder = async (condition) => {
    const resp = await salesApi.deleteOrder(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const getOrderConstants = async (condition) => {
    const resp = await salesApi.getOrderConstants(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const getProducts = async (condition) => {
    const resp = await salesApi.getProducts(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const getConstants = async (condition) => {
    const resp = await salesApi.getConstants(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const salesService = {
    searchOrder,
    viewOrder,
    createOrder,
    updateOrder,
    deleteOrder,
    getOrderConstants,
    getProducts,
    getConstants,
}

export default salesService;