import commonApi from "../api/common-api";
import { RESPONSE_STATUS } from '../constants/app-constants';
import { DATE_FORMAT } from '../config/app-config';

const uploadProductUrl = async () => {
    return commonApi.uploadProductUrl();
}

const uploadCarouselUrl = async () => {
    return commonApi.uploadCarouselUrl();
}

const getCarousels = async (condition) => {
    const resp = await commonApi.getCarousels(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const deleteCarousel = async (condition) => {
    const resp = await commonApi.deleteCarousel(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const getDashboard = async (condition) => {
    const cond = {...condition};
    cond.dateFrom = cond.dateFrom.format(DATE_FORMAT);
    cond.dateTo = cond.dateTo.format(DATE_FORMAT);

    const resp = await commonApi.getDashboard(cond);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const login = async (condition) => {
    const resp = await commonApi.login(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const changePassword = async (condition) => {
    const resp = await commonApi.changePassword(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const commonService = {
    uploadProductUrl,
    uploadCarouselUrl,
    getCarousels,
    deleteCarousel,
    getDashboard,
    login,
    changePassword,
}

export default commonService;