import httpUtils from '../utils/http-utils';
import { API_ENDPOINT } from '../config/app-config';

const SERVICE_NAME = API_ENDPOINT + '/Api/Sales';

const searchOrder = (req) => {
    return httpUtils.post(SERVICE_NAME + '/order/search', req);
}

const getOrder = (req) => {
    return httpUtils.post(SERVICE_NAME + '/order/get', req);
}

const createOrder = (req) => {
    return httpUtils.post(SERVICE_NAME + '/order', req);
}

const updateOrder = (req) => {
    return httpUtils.patch(SERVICE_NAME + '/order', req);
}

const deleteOrder = (req) => {
    return httpUtils.del(SERVICE_NAME + '/order', req);
}

const getOrderConstants = (req) => {
    return httpUtils.post(SERVICE_NAME + '/order/constants', req);
}

const getProducts = (req) => {
    return httpUtils.post(SERVICE_NAME + '/products', req);
}

const getConstants = (req) => {
    return httpUtils.post(SERVICE_NAME + '/constants', req);
}

const salesApi = {
    searchOrder,
    getOrder,
    createOrder,
    updateOrder,
    deleteOrder,
    getOrderConstants,
    getProducts,
    getConstants,
}

export default salesApi;