import React, { useState } from "react";
import { Menu, Layout } from 'antd';
import * as Icon from "react-feather";
import { useLocation, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const { Sider } = Layout;
const { SubMenu } = Menu;

const rootSubmenuKeys = ['/brand', 'master'];

const Navbar = ({ collapsed }) => {

    const dispatch = useDispatch();
    const location = useLocation();
    const { pathname } = location;

    const [openKeys, setOpenKeys] = useState(['']);

    const onOpenChange = keys => {
        const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    // const onLogout = () => {
    //     dispatch(userAction.logout());
    // }

    return (
        <Sider trigger={null} collapsible collapsed={collapsed} width="300">
            {/* <div className="logo-nmn-horizontal border-bottom"> */}
                {/* <img src="../assets/pngegg.png" alt="logo-nmn" /> */}
            {/* </div> */}
            <Menu theme="dark" mode="inline" className="my-3" selectedKeys={[pathname]} openKeys={openKeys} onOpenChange={onOpenChange}>
                {/* <Menu.Item key="/" className="mb-0 mt-0" icon={<Icon.Box className="me-2 transform-nmu" size={18} />} ><Link to="/">Home</Link></Menu.Item> */}
                <Menu.Item key="/dashboard" className="mb-0 mt-0" icon={<Icon.Box className="me-2 transform-nmu" size={18} />} ><Link to="dashboard">Dashboard</Link></Menu.Item>
                <Menu.Item key="/order" className="mb-0 mt-0" icon={<Icon.Archive className="me-2 transform-nmu" size={18} />} ><Link to="order">Order</Link></Menu.Item>
                <Menu.Item key="/carousel" className="mb-0 mt-0" icon={<Icon.BookOpen className="me-2 transform-nmu" size={18} />} ><Link to="carousel">Carousel</Link></Menu.Item>
                <SubMenu key="master" title="Master Data" icon={<Icon.Clipboard size={18} />}>
                    <Menu.Item key="/brand" className="mb-0 mt-0" ><Icon.Circle className="me-2 transform-nmu" size={7} /><Link to="brand">Brand</Link></Menu.Item>
                    <Menu.Item key="/color" className="mb-0 mt-0" ><Icon.Circle className="me-2 transform-nmu" size={7} /><Link to="color">Color</Link></Menu.Item>
                    <Menu.Item key="/diameter" className="mb-0 mt-0" ><Icon.Circle className="me-2 transform-nmu" size={7} /><Link to="diameter">Diameter</Link></Menu.Item>
                    <Menu.Item key="/model" className="mb-0 mt-0" ><Icon.Circle className="me-2 transform-nmu" size={7} /><Link to="model">Model</Link></Menu.Item>
                    <Menu.Item key="/power" className="mb-0 mt-0" ><Icon.Circle className="me-2 transform-nmu" size={7} /><Link to="power">Power</Link></Menu.Item>
                    <Menu.Item key="/product" className="mb-0 mt-0" ><Icon.Circle className="me-2 transform-nmu" size={7} /><Link to="product">Product</Link></Menu.Item>
                </SubMenu>
                {/* <Menu.Item key="/" className="mb-0 mt-0" icon={<Icon.Box className="me-2 transform-nmu" size={18} />} onClick={onLogout}><Link to="/">Logout</Link></Menu.Item> */}
            </Menu>
        </Sider>
    );
}

export default Navbar;