const getMessage = (error) => {
    const errorMsg = { title: "เกิดข้อผิดพลาด", message: null };
    if (typeof error === 'string') {
        errorMsg.message = error;
        return errorMsg;
    } else if (typeof error === 'object') {
        errorMsg.title = error.title || errorMsg.title;
        errorMsg.message = error.responseMsgTh || error.message;
        errorMsg.code = error.code;
        return errorMsg;
    } else {
        console.error('unknow error', error);
        errorMsg.message = "เกิดข้อผิดพลาดกรุณาติดต่อผู้ดูแลระบบ | Something went wrong, Please contact Administrator";
        return errorMsg;
    }
}

const errorMessageHandler = {
    getMessage
}

export default errorMessageHandler;