import React, { useState, useEffect } from 'react'
import { Upload } from 'antd';
import sessionStorageUtils from '../../utils/storage-session-utils';

const UploadImage = (props) => {

    const [fileList, setFileList] = useState(props.fileList);

    useEffect(() => {
    }, []);

    const onChangeUpload = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        props.onChange(newFileList[0]);
    };

    const reqHeader = {
        authorization: "Bearer " + sessionStorageUtils.getToken()
    }

    return (
        <Upload
            action={props.action}
            headers={reqHeader}
            listType="picture-card"
            fileList={fileList}
            onChange={onChangeUpload}
            disabled={props.disabled}
        >
            {fileList.length < 1 && '+ Upload'}
        </Upload>
    )

}

export default UploadImage;