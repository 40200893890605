import httpUtils from '../utils/http-utils';
import { API_ENDPOINT } from '../config/app-config';

const SERVICE_NAME = API_ENDPOINT + '/api/master';

// Brand
const getBrandConstants = (req) => {
    return httpUtils.post(SERVICE_NAME + '/brand/constants', req);
}

const searchBrand = (req) => {
    return httpUtils.post(SERVICE_NAME + '/brand/search', req);
}

const getBrand = (req) => {
    return httpUtils.post(SERVICE_NAME + '/brand/get', req);
}

const createBrand = (req) => {
    return httpUtils.post(SERVICE_NAME + '/brand', req);
}

const updateBrand = (req) => {
    return httpUtils.patch(SERVICE_NAME + '/brand', req);
}

const deleteBrand = (req) => {
    return httpUtils.del(SERVICE_NAME + '/brand', req);
}

// Color
const searchColor = (req) => {
    return httpUtils.post(SERVICE_NAME + '/color/search', req);
}

const getColor = (req) => {
    return httpUtils.post(SERVICE_NAME + '/color/get', req);
}

const createColor = (req) => {
    return httpUtils.post(SERVICE_NAME + '/color', req);
}

const updateColor = (req) => {
    return httpUtils.patch(SERVICE_NAME + '/color', req);
}

const deleteColor = (req) => {
    return httpUtils.del(SERVICE_NAME + '/color', req);
}

// Diameter
const searchDiameter = (req) => {
    return httpUtils.post(SERVICE_NAME + '/diameter/search', req);
}

const getDiameter = (req) => {
    return httpUtils.post(SERVICE_NAME + '/diameter/get', req);
}

const createDiameter = (req) => {
    return httpUtils.post(SERVICE_NAME + '/diameter', req);
}

const updateDiameter = (req) => {
    return httpUtils.patch(SERVICE_NAME + '/diameter', req);
}

const deleteDiameter = (req) => {
    return httpUtils.del(SERVICE_NAME + '/diameter', req);
}

// Power
const searchPower = (req) => {
    return httpUtils.post(SERVICE_NAME + '/power/search', req);
}

const getPower = (req) => {
    return httpUtils.post(SERVICE_NAME + '/power/get', req);
}

const createPower = (req) => {
    return httpUtils.post(SERVICE_NAME + '/power', req);
}

const updatePower = (req) => {
    return httpUtils.patch(SERVICE_NAME + '/power', req);
}

const deletePower = (req) => {
    return httpUtils.del(SERVICE_NAME + '/power', req);
}

// Model
const searchModel = (req) => {
    return httpUtils.post(SERVICE_NAME + '/model/search', req);
}

const getModel = (req) => {
    return httpUtils.post(SERVICE_NAME + '/model/get', req);
}

const createModel = (req) => {
    return httpUtils.post(SERVICE_NAME + '/model', req);
}

const updateModel = (req) => {
    return httpUtils.patch(SERVICE_NAME + '/model', req);
}

const deleteModel = (req) => {
    return httpUtils.del(SERVICE_NAME + '/model', req);
}

// Product
const getProductConstants = (req) => {
    return httpUtils.post(SERVICE_NAME + '/product/constants', req);
}

const searchProduct = (req) => {
    return httpUtils.post(SERVICE_NAME + '/product/search', req);
}

const getProduct = (req) => {
    return httpUtils.post(SERVICE_NAME + '/product/get', req);
}

const createProduct = (req) => {
    return httpUtils.post(SERVICE_NAME + '/product', req);
}

const updateProduct = (req) => {
    return httpUtils.patch(SERVICE_NAME + '/product', req);
}

const deleteProduct = (req) => {
    return httpUtils.del(SERVICE_NAME + '/product', req);
}

// ProductPower
const getProductPowers = (req) => {
    return httpUtils.post(SERVICE_NAME + '/productpower/powers', req);
}

const masterApi = {
    getBrandConstants,
    searchBrand,
    getBrand,
    createBrand,
    updateBrand,
    deleteBrand,
    searchColor,
    getColor,
    createColor,
    updateColor,
    deleteColor,
    searchDiameter,
    getDiameter,
    createDiameter,
    updateDiameter,
    deleteDiameter,
    searchPower,
    getPower,
    createPower,
    updatePower,
    deletePower,
    searchModel,
    getModel,
    createModel,
    updateModel,
    deleteModel,
    getProductConstants,
    searchProduct,
    getProduct,
    createProduct,
    updateProduct,
    deleteProduct,
    getProductPowers
}

export default masterApi;