export const storageKey = {
    TOKEN: "luxzylens-web-token",
}

export const RESPONSE_STATUS = {
    SUCCESS: "SUCCESS",
    FAIL: "FAIL"
}

export const RECORD_STATUS = [
    { value: "0", label: "Active" },
    { value: "1", label: "Inactive" }
];

export const PRODUCT_TYPE = {
    FASHION: "0",
    POWER: "1"
}

export const PRODUCT_TYPE_OPTION = [
    { value: "0", label: "แฟชั่น" },
    { value: "1", label: "ค่าสายตา" }
]

export const ORDER_STATUS = [
    { value: "0", label: "Pending" },
    { value: "1", label: "In Progress" },
    { value: "2", label: "Complete" },
    { value: "3", label: "Cancel" }
]

export const USER_PERMISSION = {
    READWRITE: "0",
    READONLY: "1"
}