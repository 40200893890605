import React from 'react';
import { useSelector } from 'react-redux';
import MainContainer from '../components/main-container';
import { Route, Redirect } from 'react-router-dom';

const TemplateRoute = ({ component: Component, isAdmin, ...rest }) => {

    const isAuthenticated = useSelector(state=>state.userReducer.isLogin);

    const renderComponent = (props) => {
        if (!isAuthenticated && isAdmin) {
            return (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: {
                            from: props.location,
                        }
                    }}
                />
            )
        }

        return (
            <MainContainer>
                <Component {...props} />
            </MainContainer>
        )
    }

    return (
        <Route
            {...rest}
            render={props => renderComponent(props)}
        />
    )
}

export default TemplateRoute;