import { masterType } from '../action/types';

const initialState = {
    brand: {},
    color: {},
    diameter: {},
    model: {},
    power: {},
    product: {},
}

const masterReducer = (state = initialState, action) => {
    
    const { type, payload } = action;
    
    switch (type) {
        case masterType.BRAND:
            return {
                ...state,
                brand: payload,
            }
        case masterType.COLOR:
            return {
                ...state,
                color: payload,
            }
        case masterType.DIAMETER:
            return {
                ...state,
                diameter: payload,
            }
        case masterType.MODEL:
            return {
                ...state,
                model: payload,
            }
        case masterType.POWER:
            return {
                ...state,
                power: payload,
            }
        case masterType.PRODUCT:
            return {
                ...state,
                product: payload,
            }
        default:
            return state;
    }
}

export default masterReducer;