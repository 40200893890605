import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Input, Button, Divider, Collapse, Select, Space, Popconfirm, Image } from 'antd';
import * as Icon from "react-feather";
import DataTable from '../../components/data-table';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { PlusOutlined, EditOutlined, DeleteOutlined, SearchOutlined, ClearOutlined } from '@ant-design/icons';
import loaderAction from '../../redux/action/app-loader-action';
import constantsAction from '../../redux/action/constants-action';
import notifyUtils from "../../utils/notify-utils";
import masterService from "../../services/master-service";
import { RECORD_STATUS, PRODUCT_TYPE_OPTION } from "../../constants/app-constants"
import masterAction from '../../redux/action/master-action';
import ColorPicker from '../../components/color-picker';
import commonUtils from '../../utils/common-utils';

const { Panel } = Collapse;

const SearchProduct = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const [condition, setCondition] = useState({});
    const [products, setProducts] = useState([]);
    const [constants, setConstants] = useState({brands:[],colors:[],models:[],diameters:[]});
    const isReadOnly = useSelector(state=>state.userReducer.isReadOnly);
    
    const onChangeValue = (event) => {
        const {name, value} = event.target;
        setCondition({...condition, [name]: value});
    };

    const onChangeStatus = (value) => {
        setCondition({...condition, status: value});
    };

    const onChangeBrand = (value) => {
        setCondition({...condition, brandId: value});
    };

    const onChangeColor = (value) => {
        setCondition({...condition, colorId: value});
    };

    const onChangeModel = (value) => {
        setCondition({...condition, modelId: value});
    };

    const onChangeDiameter = (value) => {
        setCondition({...condition, diameterId: value});
    };

    const onChangeType = (value) => {
        setCondition({...condition, type: value});
    };

    useEffect(() => {
        getConstants();
        onSearch();
    }, []);

    const getConstants = async (values) => {
        try {
            dispatch(loaderAction.enable());

            const res = await masterService.getProductConstants(values);
            dispatch(constantsAction.products(res.response));
            setConstants(res.response);

            dispatch(loaderAction.disable());
        } catch (err) {
            dispatch(loaderAction.disable());
            notifyUtils.error(err);
        }
    };

    const searchProduct = async (condition) => {
        try {
            dispatch(loaderAction.enable());

            const res = await masterService.searchProduct(condition);
            setProducts(res.response);

            dispatch(loaderAction.disable());
        } catch (err) {
            dispatch(loaderAction.disable());
            notifyUtils.error(err);
        }
    }

    const deleteProduct = async (condition) => {
        try {
            dispatch(loaderAction.enable());

            await masterService.deleteProduct(condition);

            notifyUtils.success("Success", "Delete Product successful.");

            onSearch();

            dispatch(loaderAction.disable());
        } catch (err) {
            dispatch(loaderAction.disable());
            notifyUtils.error(err);
        }
    }

    // const getProductPowers = async (condition) => {
    //     try {
    //         dispatch(loaderAction.enable());

    //         const res = await masterService.getProductPowers(condition);
    //         setPowers(res.response);

    //         dispatch(loaderAction.disable());
    //     } catch (err) {
    //         dispatch(loaderAction.disable());
    //         notifyUtils.error(err);
    //     }
    // }

    const columns = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            width: 60,
            render: (_, record) => {
                return <Image width={60} src={commonUtils.getProductImage(record.imgName)} />
            }
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: 100,
            render: (_, record) => {
                return PRODUCT_TYPE_OPTION.find(item => item.value === record.type)?.label;
            }
        },
        {
            title: 'Brand',
            dataIndex: 'brand',
            key: 'brand',
            width: 150,
            render: (_, record) => {
                return record.brand.name;
            }
        },
        {
            title: 'Product Name',
            dataIndex: 'name',
            key: 'name',
            width: 200
        },
        // {
        //     title: 'Diameter',
        //     dataIndex: 'diameter',
        //     key: 'diameter',
        //     width: 200,
        //     render: (_, record) => {
        //         return record.diameter.name;
        //     }
        // },
        {
            title: 'Color',
            dataIndex: 'color',
            key: 'color',
            width: 200,
            render: (_, record) => {
                return <Space><ColorPicker readOnly={true} color={record?.color?.color} /> {record?.color?.name}</Space>;
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            render: (_, record) => {
                return RECORD_STATUS.find(item => item.value === record.status)?.label;
            }
        },
        {
            dataIndex: 'view',
            width: 100,
            render: (_, record) => {
                return <Space>
                            <Button type="primary" shape="circle" size="large" onClick={() => onView(record)} icon={<EditOutlined />} />
                            <Popconfirm title="sure to delete?" onConfirm={() => onDelete(record)} disabled={isReadOnly}>
                                <Button type="primary" shape="circle" size="large" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Space>
            }
        }
    ];

    const onSearch = () => {
        searchProduct(condition);
    };

    const onClear = () => {
        setCondition({});
    };

    const onView = async (record) => {
        const powers = record.powers.map(item => {
                            return item.powerId;
                        });
        const newData = {
            isAdd: false,
            data: {...record, oriPowers:record.powers, powers:powers},
            fileList: [{
                uid: '-1',
                name: record.imgName,
                status: 'done',
                url: commonUtils.getProductImage(record.imgName),
            }]
        }

        dispatch(masterAction.product(newData));
        history.push("/product-view");
    }
    
    const onAdd = () => {
        const newData = {
            isAdd: true,
            data: {
                name: null,
                status: "0",
            },
            fileList: [],
        }
        dispatch(masterAction.product(newData));
        history.push("/product-view");
    }

    const onDelete = (record) => {
        deleteProduct({id:record.id});
    }

    const title = () => {
        return (
            <Space>
                <h5>Total Records : {products.length}</h5>
                <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={onAdd} />
            </Space>
        );
    }

    return (
        <div>
            <h2 className="color-primary-title">Product</h2>
            <Divider className="mt-2 mb-4" />
            <Card className="mt-4" bordered={false}>
                <Collapse className="mb-5" defaultActiveKey={['1']}>
                    <Panel header={<> <Icon.Search className="me-2" size={14} /> Filter</>} key="1">
                        <Form layout="vertical">
                            <Row gutter={20}>
                                <Col span={12} xs={24} sm={24} md={12}>
                                    <Form.Item label="Product Name:">
                                        <Input name="name" onChange={onChangeValue} value={condition.name} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} xs={24} sm={24} md={12}>
                                    <Form.Item label="Brand:">
                                        <Select name="brandId" onChange={onChangeBrand} value={condition.brandId}
                                            showSearch allowClear optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {constants.brands.map((item)=>{
                                                return <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12} xs={24} sm={24} md={12}>
                                    <Form.Item label="Color:">
                                        <Select name="colorId" onChange={onChangeColor} value={condition.colorId}
                                            showSearch allowClear optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {constants.colors.map((item)=>{
                                                return <Select.Option key={item.value} value={item.value} label={item.label}><Space><ColorPicker readOnly={true} color={item.color} />{item.label}</Space></Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12} xs={24} sm={24} md={12}>
                                    <Form.Item label="Model:">
                                        <Select name="modelId" onChange={onChangeModel} value={condition.modelId}
                                            showSearch allowClear optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {constants.models.map((item)=>{
                                                return <Select.Option key={item.value} value={item.value}>{item.label + " (" + constants.brands.find(x => x.value === item.brandId).label + ")"}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12} xs={24} sm={24} md={12}>
                                    <Form.Item label="Diameter:">
                                        <Select name="diameterId" onChange={onChangeDiameter} value={condition.diameterId}>
                                            {constants.diameters.map((item)=>{
                                                return <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12} xs={24} sm={24} md={12}>
                                    <Form.Item label="Type:">
                                        <Select name="type" onChange={onChangeType} value={condition.type}>
                                            {PRODUCT_TYPE_OPTION.map((item)=>{
                                                return <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12} xs={24} sm={24} md={12}>
                                    <Form.Item label="Status:">
                                        <Select name="status" onChange={onChangeStatus} value={condition.status}>
                                            {RECORD_STATUS.map((item)=>{
                                                return <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col span={12} xs={24} sm={24} md={12}>
                                    <Form.Item>
                                        <Space>
                                            <Button type="primary" onClick={onSearch} icon={<SearchOutlined />}>Submit</Button>
                                            <Button onClick={onClear} icon={<ClearOutlined />}>Clear</Button>
                                        </Space>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>

                <DataTable scroll={{ x: 'max-content' }} columns={columns} dataSource={products} title={title} rowKey="id" />
            </Card>
        </div>
    );

}

export default SearchProduct;