import { constantsType } from '../action/types';

const initialState = {
    brands: [],
    products: {},
}

const constantsReducer = (state = initialState, action) => {
    
    const { type, payload } = action;
    
    switch (type) {
        case constantsType.BRANDS:
            return {
                ...state,
                brands: payload,
            }
        case constantsType.PRODUCTS:
            return {
                ...state,
                products: payload,
            }
        default:
            return state;
    }
}

export default constantsReducer;