import httpUtils from '../utils/http-utils';
import { API_ENDPOINT } from '../config/app-config';

const SERVICE_NAME = API_ENDPOINT + '/api/common';

const uploadProductUrl = () => {
    return SERVICE_NAME + '/uploadProduct';
}

const uploadCarouselUrl = () => {
    return SERVICE_NAME + '/uploadCarousel';
}

const getCarousels = (req) => {
    return httpUtils.post(SERVICE_NAME + '/carousel', req);
}

const deleteCarousel = (req) => {
    return httpUtils.del(SERVICE_NAME + '/carousel', req);
}

const getDashboard = (req) => {
    return httpUtils.post(SERVICE_NAME + '/dashboard', req);
}

const login = (req) => {
    return httpUtils.post(SERVICE_NAME + '/login', req);
}

const changePassword = (req) => {
    return httpUtils.patch(SERVICE_NAME + '/changePassword', req);
}

const commonApi = {
    uploadProductUrl,
    uploadCarouselUrl,
    getCarousels,
    deleteCarousel,
    getDashboard,
    login,
    changePassword,
}

export default commonApi;