import React, { useState, useEffect } from 'react';
import { Form, Button, InputNumber, Select, Image, Popconfirm, Card, Row, Col, Space, Input, Divider, Table } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import salesAction from '../../redux/action/sales-action';
import DataTable from '../../components/data-table';
import commonUtils from '../../utils/common-utils';
import { PRODUCT_TYPE_OPTION, ORDER_STATUS } from '../../constants/app-constants'
import { DeleteOutlined, RocketOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons';
import * as Icon from "react-feather";
import loaderAction from '../../redux/action/app-loader-action';
import notifyUtils from "../../utils/notify-utils";
import salesService from "../../services/sales-service";

const ShoppingCart = () => {

    const dispatch = useDispatch();
    const cart = useSelector(state=>state.salesReducer.cart);
    const [data, setData] = useState([]);
    const [form] = Form.useForm();
    const [shipping, setShipping] = useState({});
    const invalidForm = data.length === 0 || data.find(x => x.quantity === 0 || (x.type === PRODUCT_TYPE_OPTION[1].value && x.powerId === null));

    useEffect(() => {
        setData(cart);
    }, []);

    useEffect(() => {
        dispatch(salesAction.cart([...data]));
    }, [data]);

    const onChangeValue = (event) => {
        const {name, value} = event.target;
        setShipping({...shipping, [name]: value});
    };

    const onChangeQuantity = (value, index) => {
        const newData = [...data];
        newData[index].quantity = value;
        setData(newData);
    }

    const onBlurQuantity = (event, index) => {
        const {name, value} = event.target;
        const newData = [...data];
        newData[index].quantity = value;
        setData(newData);
    }

    const onChangePower = (value, index) => {
        const newData = [...data];
        newData[index].powerId = value;
        setData(newData);
    }

    const onDeleteRow = (index) => {
        const newData = [...data];
        newData.splice(index, 1);
        setData(newData);
    }

    const checkPowerDisabled = (product, value) => {
        const index = cart.findIndex(item => item.productId === product.productId && item.powerId === value);
        return index >= 0;
    }

    const columns = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            width: 80,
            render: (_, record) => {
                return <Image width={80} src={commonUtils.getProductImage(record.product.imgName)} />
            }
        },
        {
            title: 'Name (ชื่อสินค้า)',
            dataIndex: 'name',
            key: 'name',
            width: 200,
            render: (_, record) => {
                return record.name;
            }
        },
        {
            title: 'Power (ค่าสายตา)',
            dataIndex: 'powerId',
            key: 'powerId',
            width: 200,
            render: (_, record, index) => {
                return record.type === PRODUCT_TYPE_OPTION[0].value ?
                        PRODUCT_TYPE_OPTION[0]?.label
                        : <Select style={{width: "150px"}} name="status" placeholder="please select" 
                            value={record.powerId} onChange={(value)=>onChangePower(value, index)}>
                            {record.product.powers.map((p)=>{
                                return <Select.Option key={p.value} value={p.value} disabled={checkPowerDisabled(record, p.value)}>{p.label}</Select.Option>
                            })}
                        </Select>
            }
        },
        {
            title: 'Quantity (จำนวน)',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 200,
            render: (_, record, index) => {
                return <InputNumber defaultValue={0} min={0} value={record.quantity} onBlur={(e)=>onBlurQuantity(e, index)} />
            }
        },
        {
            dataIndex: 'Action',
            width: 100,
            render: (_, record, index) => {
                return <Popconfirm title="sure to delete?" onConfirm={() => onDeleteRow(index)}>
                            <Button type="primary" shape="circle" size="large" icon={<DeleteOutlined />} />
                        </Popconfirm>
            }
        }
    ];

    const addOrder = async (values) => {
        try {
            dispatch(loaderAction.enable());

            await salesService.createOrder(values);

            notifyUtils.success("Success", "Create Order successful.");

            onResetFields();
            setData([]);
            dispatch(salesAction.clear());

            dispatch(loaderAction.disable());
        } catch (err) {
            dispatch(loaderAction.disable());
            notifyUtils.error(err);
        }
    };

    const onFinish = (values) => {
        const newData = {...values};
        newData.details = cart;
        newData.status = ORDER_STATUS[0].value;
        console.log(newData);

        addOrder(newData);
    }

    const onResetFields = () => {
        form.resetFields();
    }

    const onSummary = () => {
        return <Table.Summary>
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={2} />
                        <Table.Summary.Cell index={2}>
                            <h5>Total Quantity (จำนวนทั้งหมด)</h5>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3} colSpan={2}>
                            <h5>{data.reduce((prev,next) => Number(prev) + Number(next.quantity),0)} Pcs (ชิ้น)</h5>
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                </Table.Summary>
    }

    return (
        <>
            <Card>
                <DataTable scroll={{ x: 'max-content' }} columns={columns} dataSource={data} rowKey="key" summary={onSummary} />

                <Divider className="mt-2 mb-4" />
                <Row> 
                    <Col span={12} xs={24} sm={24} md={12}>
                        <Space>
                            <RocketOutlined /><h5>Shipping (การจัดส่ง)</h5>
                        </Space>
                    </Col>
                </Row>
                <Form name="basic" layout="vertical" className="my-3"
                    form={form}
                    onFinish={onFinish}
                    initialValues={data}
                    labelCol={{ span: 24, }} wrapperCol={{ span: 24, }}
                >
                    <Form.Item
                        label={<div className="d-flex"> <Icon.Archive className="me-1 color-primary" size={18} /> Name (ชื่อ-นามสกุล) </div>}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "Please input name!",
                            },
                        ]}
                    >
                        <Input name="name" className="w-100" value={data.name} onChange={onChangeValue} />
                    </Form.Item>
                    <Form.Item
                        label={<div className="d-flex"> <Icon.Bell className="me-1 color-primary" size={18} /> Email (อีเมล) </div>}
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: "Please input email!",
                            },
                            {
                                type: 'email',
                                message: "Please input email correct format!",
                            }
                        ]}
                    >
                        <Input name="email" className="w-100" value={data.email} onChange={onChangeValue} />
                    </Form.Item>
                    <Form.Item
                        label={<div className="d-flex"> <Icon.Box className="me-1 color-primary" size={18} /> Mobile (เบอร์โทรศัพท์)</div>}
                        name="mobile"
                        rules={[
                            {
                                required: true,
                                message: "Please input mobile!",
                            },
                        ]}
                    >
                        <Input name="mobile" className="w-100" value={data.mobile} onChange={onChangeValue} />
                    </Form.Item>
                    <Form.Item
                        label={<div className="d-flex"> <Icon.Inbox className="me-1 color-primary" size={18} /> Line ID </div>}
                        name="lineId"
                        rules={[
                            {
                                required: true,
                                message: "Please input line id!",
                            },
                        ]}
                    >
                        <Input name="lineId" className="w-100" value={data.lineId} onChange={onChangeValue} />
                    </Form.Item>
                    <Form.Item
                        label={<div className="d-flex"> <Icon.Codepen className="me-1 color-primary" size={18} /> Shipping Address (ที่อยู่จัดส่ง) </div>}
                        name="shipTo"
                        rules={[
                            {
                                required: true,
                                message: "Please input shipping address!",
                            },
                        ]}
                    >
                        <Input.TextArea name="shipTo" className="w-100" rows={3} value={data.shipTo} onChange={onChangeValue} />
                    </Form.Item>
                    <Form.Item
                        label={<div className="d-flex"> <Icon.Meh className="me-1 color-primary" size={18} /> Order Detail (รายละเอียดการสั่งซื้อ) </div>}
                        name="detail"
                    >
                        <Input.TextArea name="detail" className="w-100" rows={3} value={data.detail} onChange={onChangeValue} />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            span: 24,
                        }}
                    >
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={invalidForm} icon={<SaveOutlined />}>Save</Button>
                            <Button onClick={onResetFields} icon={<CloseOutlined />}>Cancel</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Card>
        </>
    )
}

export default ShoppingCart;