import React from 'react';
import ShoppingCart from './shopping-cart';

const Cart = () => {

    return (
        <ShoppingCart />
    )
}

export default Cart;