import React from 'react';
import SearchProduct from './search';

const Product = () => {

    return (
        <SearchProduct />
    )
}

export default Product;