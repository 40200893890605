import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Statistic, DatePicker, Form, Button, Typography, Divider } from 'antd';
import { 
    DislikeOutlined, 
    LikeOutlined, 
    Loading3QuartersOutlined, 
    ExceptionOutlined, 
    AreaChartOutlined, 
    SearchOutlined,
    DatabaseOutlined,
} from '@ant-design/icons';
import { DISPLAY_DATE_FORMAT } from '../../config/app-config'
import moment from 'moment';
import loaderAction from '../../redux/action/app-loader-action';
import notifyUtils from "../../utils/notify-utils";
import { useDispatch } from 'react-redux';
import commonService from '../../services/common-service';

const Dashboard = () => {

    const dispatch = useDispatch();
    const [condition, setCondition] = useState({dateFrom: null, dateTo: null});
    const [data, setData] = useState({bestSeller:[]});

    useEffect(() => {
        const now = new Date();
        const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
        const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        setCondition({dateFrom:moment(firstDay), dateTo:moment(lastDay)});
        getDashboard({dateFrom:moment(firstDay), dateTo:moment(lastDay)});
    }, []);

    const onChangeDateFrom = (value, dateString) => {
        setCondition({...condition, dateFrom: value});
    };

    const onChangeDateTo = (value, dateString) => {
        setCondition({...condition, dateTo: value});
    };

    const getDashboard = async (condition) => {
        try {
            dispatch(loaderAction.enable());

            const res = await commonService.getDashboard(condition);
            setData(res.response);

            dispatch(loaderAction.disable());
        } catch (err) {
            dispatch(loaderAction.disable());
            notifyUtils.error(err);
        }
    }

    const onSearch = () => {
        getDashboard({...condition});
    }

    const bestSeller = (
        data.bestSeller.map(item => {
            return <>
                    <Divider className="mt-2 mb-4" />
                    <Typography.Text key={item.id}>
                        {item.brandName} {item.name} (Sold {item.quantity} pcs.)
                    </Typography.Text>
                </>
        })
    )

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={12} xs={24} sm={24} md={12}>
                    <Card>
                        <Form labelCol={6}>
                            <Row gutter={16}>
                                <Col span={12} xs={24} sm={24} md={12}>
                                    <Form.Item label="From">
                                        <DatePicker name="dateFrom" value={condition.dateFrom} 
                                            format={DISPLAY_DATE_FORMAT} onChange={onChangeDateFrom} 
                                            style={{width:'100%'}} allowClear={false} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} xs={24} sm={24} md={12}>
                                    <Form.Item label="To">
                                        <DatePicker name="dateTo" value={condition.dateTo} 
                                            format={DISPLAY_DATE_FORMAT} onChange={onChangeDateTo} 
                                            style={{width:'100%'}} allowClear={false} />
                                    </Form.Item>
                                </Col>
                                <Col span={24} xs={24} sm={24} md={24}>
                                    <Button type='primary' icon={<SearchOutlined />} onClick={onSearch}>Search</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
                <Col span={12} xs={24} sm={24} md={12}>
                    <Card>
                        <Statistic key="totalOrder" title="Total Order" value={data.order} valueStyle={{ color: '#38d3a8' }} prefix={<AreaChartOutlined />} suffix={" / " + data.orderTotal} />
                    </Card>
                </Col>
                <Col span={6} xs={24} sm={24} md={6}>
                    <Card>
                        <Statistic key="pending" title="Pending" value={data.pending} valueStyle={{ color: '#2c39a9' }} prefix={<ExceptionOutlined />} />
                    </Card>
                </Col>
                <Col span={6} xs={24} sm={24} md={6}>
                    <Card>
                        <Statistic key="inProgress" title="In Progess" value={data.inProgress} valueStyle={{ color: '#ecad68' }} prefix={<Loading3QuartersOutlined />} />
                    </Card>
                </Col>
                <Col span={6} xs={24} sm={24} md={6}>
                    <Card>
                        <Statistic key="complete" title="Complete" value={data.complete} valueStyle={{ color: '#3f8600' }} prefix={<LikeOutlined />} />
                    </Card>
                </Col>
                <Col span={6} xs={24} sm={24} md={6}>
                    <Card>
                        <Statistic key="cancel" title="Cancel" value={data.cancel} valueStyle={{ color: '#cf1322' }} prefix={<DislikeOutlined />} />
                    </Card>
                </Col>
                <Col span={6} xs={24} sm={24} md={6}>
                    <Card>
                        <Statistic key="totalProduct" title="Total Product" value={data.product} valueStyle={{ color: '#2c39a9' }} prefix={<DatabaseOutlined />} />
                    </Card>
                </Col>
                <Col span={18} xs={24} sm={24} md={18}>
                    <Card>
                        <Statistic key="top5" title="Top 5 Best Seller (Complete Only)" value=" " valueStyle={{ color: '#2c39a9' }} prefix={bestSeller} />
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Dashboard;