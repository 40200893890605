import React, { useState, useEffect } from 'react';
import { Image, Card, Row, Col, Collapse, Form, Select, Space } from 'antd';
import loaderAction from '../../redux/action/app-loader-action';
import salesAction from '../../redux/action/sales-action';
import notifyUtils from "../../utils/notify-utils";
import salesService from "../../services/sales-service";
import { useSelector, useDispatch } from 'react-redux';
import DataTable from '../../components/data-table';
import commonUtils from '../../utils/common-utils';
import ProductSelect from '../../components/product-select';
import * as Icon from "react-feather";
import ColorPicker from '../../components/color-picker';
import { PRODUCT_TYPE_OPTION } from '../../constants/app-constants';

const { Panel } = Collapse;

const ProductList = () => {

    const dispatch = useDispatch();
    const [condition, setCondition] = useState({brands:[], models:[], colors:[], types:[], diameters:[], powers:[]});
    const [products, setProducts] = useState([]);
    const [filters, setFilters] = useState([]);
    const cart = useSelector(state=>state.salesReducer.cart);
    const filter = useSelector(state=>state.salesReducer.filter);
    // const constants = useSelector(state=>state.salesReducer.constants);
    const [constants, setConstants] = useState({brands:[], models:[], colors:[], types:[], diameters:[], powers:[]});

    useEffect(() => {
        // dispatch(salesAction.clear());
        initPage();
    }, []);

    useEffect(() => {
        setCondition({...filter});
    }, [filter]);

    useEffect(() => {
        const newFilter = [...products];
        setFilters(newFilter.filter(a => 
                (condition.brands.length === 0 || condition.brands.includes(a.brandId))
            ).filter(b =>
                (condition.models.length === 0 || condition.models.includes(b.modelId))
            ).filter(c =>
                (condition.colors.length === 0 || condition.colors.includes(c.colorId))
            ).filter(d =>
                (condition.types.length === 0 || condition.types.includes(d.type))
            ).filter(e =>
                (condition.diameters.length === 0 || condition.diameters.includes(e.diameterId))
            ).filter(f =>
                (condition.powers.length === 0 || f.powers.some(ff => condition.powers.includes(ff.powerId)))
            )
        );
    }, [condition]);

    const onChangeBrands = (value) => {
        setCondition({...condition, brands: value});
    };

    const onChangeModels = (value) => {
        setCondition({...condition, models: value});
    };

    const onChangeColors = (value) => {
        setCondition({...condition, colors: value});
    };

    const onChangeTypes = (value) => {
        setCondition({...condition, types: value});
    };

    const onChangeDiameters = (value) => {
        setCondition({...condition, diameters: value});
    };

    const onChangePowers = (value) => {
        setCondition({...condition, powers: value});
    };

    const initPage = async () => {
        await getConstants();
        await getProducts();
    }

    const getConstants = async (values) => {
        try {
            dispatch(loaderAction.enable());

            const res = await salesService.getConstants(values);
            dispatch(salesAction.constants(res.response));
            setConstants(res.response);

            dispatch(loaderAction.disable());
        } catch (err) {
            dispatch(loaderAction.disable());
            notifyUtils.error(err);
        }
    };

    const getProducts = async (values) => {
        try {
            dispatch(loaderAction.enable());

            const res = await salesService.getProducts(values);
            setProducts(res.response);
            setFilters(res.response);

            dispatch(loaderAction.disable());
        } catch (err) {
            dispatch(loaderAction.disable());
            notifyUtils.error(err);
        }
    };

    const columns = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            width: 70,
            render: (_, record) => {
                return <Image width={70} src={commonUtils.getProductImage(record.imgName)} />
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 400,
            render: (_, record) => {
                return record.name;
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: 400,
            render: (_, record) => {
                const cartz = cart.filter(item => item.productId === record.id);
                if (cartz.length > 0) {
                    return <ProductSelect data={cartz} product={cartz[0].product} />
                }
                else {
                    const newProduct = {...record};
                    // console.log(newProduct);
                    newProduct.powers = newProduct.powers.map(item => {
                        return constants.powers.find(x => x.value === item.powerId);
                    })
    
                    const data = [{
                        key: commonUtils.random(16),
                        type: record.type,
                        productId: record.id,
                        name: record.name,
                        quantity: 0,
                        powerId: null,
                        product: newProduct,
                        status: record.status,
                    }]
                    return <ProductSelect data={data} product={newProduct} key="key" />
                }
            }
        }
    ];

    return (
        <>
            <Card>
                <Collapse className="mb-5" defaultActiveKey={['1']}>
                    <Panel header={<> <Icon.Search className="me-2" size={14} /> Filter</>} key="1">
                        <Form layout="vertical">
                            <Row gutter={20}>
                                <Col span={6} xs={24} sm={24} md={6}>
                                    <Form.Item label="Brands (ยี่ห้อ)">
                                        <Select name="brands" onChange={onChangeBrands} value={condition.brands}
                                            placeholder="เลือกยี่ห้อ"
                                            mode="multiple"
                                            showSearch allowClear optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {constants.brands.map((item)=>{
                                                return <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6} xs={24} sm={24} md={6}>
                                    <Form.Item label="Models (รุ่น)">
                                        <Select name="models" onChange={onChangeModels} value={condition.models}
                                            placeholder="เลือกรุ่น"
                                            mode="multiple"
                                            showSearch allowClear optionFilterProp="children"
                                            filterOption={(input, option) =>{
                                                if(option.children){
                                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                }else if(option.label){
                                                    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                }
                                            }}
                                        >
                                            {
                                                constants.brands.filter(x => condition.brands.length === 0 || condition.brands.includes(x.value)).map((brand)=> {
                                                    return <Select.OptGroup label={brand.label} key={brand.value}>
                                                        {
                                                            constants.models.filter(f => f.brandId === brand.value).map((item)=>{
                                                                return <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                                                            })
                                                        }
                                                    </Select.OptGroup> 
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6} xs={24} sm={24} md={6}>
                                    <Form.Item label="Colors (สี)">
                                        <Select name="colors" onChange={onChangeColors} value={condition.colors}
                                            placeholder="เลือกสี"
                                            mode="multiple"
                                            showSearch allowClear optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {constants.colors.map((item)=>{
                                                return <Select.Option key={item.value} value={item.value} label={item.label}><Space><ColorPicker readOnly={true} color={item.color} />{item.label}</Space></Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6} xs={24} sm={24} md={6}>
                                    <Form.Item label="Type (ชนิดสินค้า)">
                                        <Select name="types" onChange={onChangeTypes} value={condition.types}
                                            placeholder="เลือกชนิดสินค้า"
                                            mode="multiple"
                                            showSearch allowClear optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {PRODUCT_TYPE_OPTION.map((item)=>{
                                                return <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col span={6} xs={24} sm={24} md={6}>
                                    <Form.Item label="Diameter (เส้นผ่านศูนย์กลาง)">
                                        <Select name="diameters" onChange={onChangeDiameters} value={condition.diameters}
                                            placeholder="เลือกเส้นผ่านศูนย์กลาง"
                                            mode="multiple"
                                            showSearch allowClear optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {constants.diameters.map((item)=>{
                                                return <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6} xs={24} sm={24} md={6}>
                                    <Form.Item label="Power (ค่าสายตา)">
                                        <Select name="powers" onChange={onChangePowers} value={condition.powers}
                                            placeholder="เลือกค่าสายตา"
                                            mode="multiple"
                                            showSearch allowClear optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {constants.powers.map((item)=>{
                                                return <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>

                <DataTable scroll={{ x: 'max-content' }} columns={columns} dataSource={filters} showHeader={false} rowKey="id" pagination={{defaultPageSize: 20}} />
            </Card>
        </>
    )
}

export default ProductList;