export const appLoaderType = {
    ENABLE: "LOADER_ENABLE",
    DISABLE: "LOADER_DISABLE"
}

export const userType = {
    LOGIN: "LOGIN_USER",
    LOGOUT: "LOGOUT_USER",
    READONLY: "READONLY_USER",
}

export const masterType = {
    BRAND: "BRAND_MASTER",
    COLOR: "COLOR_MASTER",
    MODEL: "MODEL_MASTER",
    DIAMETER: "DIAMETER_MASTER",
    POWER: "POWER_MASTER",
    PRODUCT: "PRODUCT_MASTER",
}

export const salesType = {
    ORDER: "ORDER_SALES",
    CART: "CART_SALES",
    PRODUCTS: "PRODUCTS_SALES",
    CONSTANTS: "CONSTANTS_SALES",
    FILTER: "FILTER_SALES",
    CLEAR: "CLEAR_SALES",
}

export const constantsType = {
    BRANDS: "BRANDS_CONSTANT",
    PRODUCTS: "PRODUCTS_CONSTANT",
}