import 'antd/dist/antd.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/color.css';
import './styles/styles-global.css';
import './styles/fix-styles.css';

import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import configStore from './config/redux-store-config';

import AppRouteContainer from './route';

const App = () => {

  const [storagePersisted, setStoragePersisted] = useState(false);
  const [store, setStore] = useState(null);
  const [persistor, setPersistor] = useState(null);

  useEffect(() => {
    const { store, persistor } = configStore(() => {
      setStoragePersisted(true);
    });
    setStore(store);
    setPersistor(persistor);
  }, []);

  if (!storagePersisted) {
    return null;
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppRouteContainer />
      </PersistGate>
    </Provider>
  )

}

export default App;