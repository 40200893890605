import React from "react";
import { Row, Col, Space, Layout, Typography } from "antd";
import * as Icon from "react-feather";
import { BsLine, BsFacebook } from "react-icons/bs";

const Footer = () => {

    return (
        <>
            <Layout.Footer>
                <Row gutter={16} className="rowContact">
                    <Col span={24} style={{textAlign: 'center'}}>
                        <h4>บริษัท ทรัพย์ออเดอร์ จำกัด</h4>
                        88/215 ถนนเลียบทางด่วน แขวงทุ่งครุ เขตทุ่งครุ กรุงเทพมหานคร 10140
                    </Col>
                </Row>
                <Row gutter={16} className="rowContact">
                    <Col span={3} xs={0} sm={0} md={3}></Col>
                    <Col span={7} xs={12} sm={12} md={7}>
                        <Space>
                            <Icon.PhoneCall />
                            <div>Luxzylens</div>
                        </Space>
                        <div className="contact">
                            <a href='tel:0897707701' rel="noreferrer" target='_blank' className="linkContact">
                                089 770 7701
                            </a>
                        </div>
                    </Col>
                    <Col span={7} xs={12} sm={12} md={7}>
                        <Space>
                            <BsFacebook />
                            <div>Luxzylens_official</div>
                        </Space>
                        <div className="contact">
                            <a href='https://www.facebook.com/luxzylens' rel="noreferrer" target='_blank' className="linkContact">
                                @luxzylens
                            </a>
                        </div>
                    </Col>
                    <Col span={7} xs={12} sm={12} md={7}>
                        <Space>
                            <BsLine />
                            <div>Luxzylens_official</div>
                        </Space>
                        <div className="contact">
                            <a href='https://line.me/ti/p/@luxzylens' rel="noreferrer" target='_blank' className="linkContact">
                                @luxzylens
                            </a>
                        </div>
                    </Col>
                    <Col span={3} xs={0} sm={0} md={3}></Col>
                    <Col span={7} xs={12} sm={12} md={7}>
                        <Space>
                            <Icon.PhoneCall />
                            <div>Montralens_official</div>
                        </Space>
                        <div className="contact">
                            <a href="tel:0830161888" rel="noreferrer" target="_blank" className="linkContact">
                                083 016 1888
                            </a>
                        </div>
                    </Col>
                    <Col span={7} xs={12} sm={12} md={7}>
                        <Space>
                            <BsFacebook />
                            <div>Montralens_official</div>
                        </Space>
                        <div className="contact">
                            <a href="https://www.facebook.com/montralens.official" rel="noreferrer" target="_blank" className="linkContact">
                                @montralens.official
                            </a>
                        </div>
                    </Col>
                    <Col span={7} xs={12} sm={12} md={7}>
                        <Space>
                            <BsLine />
                            <div>Montralens_official</div>
                        </Space>
                        <div className="contact">
                            <a href="https://line.me/ti/p/@montralens" rel="noreferrer" target="_blank" className="linkContact">
                                @montralens.official
                            </a>
                        </div>
                    </Col>
                </Row>
            </Layout.Footer>
            
            <Typography.Text className="copyright">
                copyright © {new Date().getFullYear()} . www.vip-lens.com .all rights reserved
            </Typography.Text>
        </>
    )
}

export default Footer;