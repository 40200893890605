import React from "react";
import { Badge, Layout, Dropdown, Avatar, Button, Space, Affix, Row, Col } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DownOutlined,
  UserOutlined,
  HomeOutlined,
  ShoppingCartOutlined,
  SkinOutlined,
  InboxOutlined,
  EyeOutlined,
  ExperimentOutlined,
} from "@ant-design/icons";
import HeaderMenu from "./menu";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import salesAction from '../../redux/action/sales-action';
import { PRODUCT_TYPE } from "../../constants/app-constants";

const Header = ({ collapsed, toggle }) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthenticated = useSelector(state=>state.userReducer.isLogin);
  const user = useSelector(state=>state.userReducer.user);
  const filter = useSelector(state=>state.salesReducer.defaultFilter);

  const onHome = () => {
    history.push("/");
  };

  const onCart = () => {
    history.push("/cart");
  };

  const onFilterFashion = () => {
    dispatch(salesAction.filter({...filter, types:[PRODUCT_TYPE.FASHION]}));
  };

  const onFilterPower = () => {
    dispatch(salesAction.filter({...filter, types:[PRODUCT_TYPE.POWER]}));
  };

  const onFilterProductSolution = () => {
    dispatch(salesAction.filter({...filter, brands:[15]}));
  };

  const onFilterProductBox = () => {
    dispatch(salesAction.filter({...filter, brands:[16]}));
  };

  return (
    <Affix>
      <Layout.Header className="site-layout-background">
        {
          isAuthenticated ?
          React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
          className: "trigger",
          onClick: toggle,
          style: { color: "white", marginRight: '15px' },
          })
          : null
        }
        {
          React.createElement(HomeOutlined, {
          onClick: onHome,
          style: { color: "white", width: "50px" },
          size: "large",
          })
        }
        <Space>
          <h1 className="linkHome" onClick={onHome}>VIP-Lens</h1>
          <Button type="default" onClick={onFilterFashion} icon={<SkinOutlined />}>แฟชั่น</Button>
          <Button type="default" onClick={onFilterPower} icon={<EyeOutlined />}>ค่าสายตา</Button>
          <Button type="default" onClick={onFilterProductSolution} icon={<ExperimentOutlined />}>น้ำยา</Button>
          <Button type="default" onClick={onFilterProductBox} icon={<InboxOutlined />}>ตลับ</Button>
        </Space>
        
        <div className="ms-auto">
          <Space>
            <Button type="primary" onClick={onCart} icon={<ShoppingCartOutlined />}>Cart</Button>
            {/* {
              isAuthenticated ? */}
              <Dropdown overlay={<HeaderMenu />}>
                <span
                  style={{ color: "white" }}
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <Badge size="small" count={user.username}>
                    <Avatar
                      className="me-2 "
                      style={{ backgroundColor: "#F2F2F2" }}
                      size="30"
                      icon={
                        <UserOutlined
                          className="color-primary"
                          style={{ transform: "translateY(-5px)", color: "#365268" }}
                        />
                      }
                    />
                  </Badge>
                  <DownOutlined className=" transform-nmu" />
                </span>
              </Dropdown>
              {/* : null
            } */}
          </Space>
        </div>
      </Layout.Header>
    </Affix>
  );
};

export default Header;
