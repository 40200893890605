import React from 'react';
import SearchColor from './search';

const Color = () => {

    return (
        <SearchColor />
    )
}

export default Color;