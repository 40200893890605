import { appLoaderType } from './types';

const enable = () => {
    return {
        type: appLoaderType.ENABLE
    }
}

const disable = () => {
    return {
        type: appLoaderType.DISABLE
    }
}

const appLoaderAction = {
    enable,
    disable
}

export default appLoaderAction;