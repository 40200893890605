import { storageKey } from '../constants/app-constants';

const getItem = (key) => {
    return sessionStorage.getItem(key);
}

const setItem = (key, value) => {
    return sessionStorage.setItem(key, value);
}

const removeItem = (key) => {
    return sessionStorage.removeItem(key);
}

const clear = () => {
    return sessionStorage.clear();
}

const getToken = () => {
    return getItem(storageKey.TOKEN);
}

const setToken = (token) => {
    return setItem(storageKey.TOKEN, token);
}

const removeToken = () => {
    return removeItem(storageKey.TOKEN);
}

const sessionStorageUtils = {
    getItem,
    setItem,
    removeItem,
    clear,
    getToken,
    setToken,
    removeToken,
}

export default sessionStorageUtils;