import { masterType } from "./types";

const brand = (obj) => {
    return {
        type: masterType.BRAND,
        payload: obj,
    }
}

const color = (obj) => {
    return {
        type: masterType.COLOR,
        payload: obj,
    }
}

const diameter = (obj) => {
    return {
        type: masterType.DIAMETER,
        payload: obj,
    }
}

const model = (obj) => {
    return {
        type: masterType.MODEL,
        payload: obj,
    }
}

const power = (obj) => {
    return {
        type: masterType.POWER,
        payload: obj,
    }
}

const type = (obj) => {
    return {
        type: masterType.TYPE,
        payload: obj,
    }
}

const product = (obj) => {
    return {
        type: masterType.PRODUCT,
        payload: obj,
    }
}

const masterAction = {
    brand,
    color,
    diameter,
    model,
    power,
    type,
    product,
}

export default masterAction;