import React, { useState, useEffect } from 'react';
import { Card, Button, Space, Popconfirm, Image, Divider, Modal } from 'antd';
import DataTable from '../../components/data-table';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import commonService from '../../services/common-service';
import loaderAction from '../../redux/action/app-loader-action';
import notifyUtils from "../../utils/notify-utils";
import commonUtils from '../../utils/common-utils';
import UploadImage from '../../components/upload-image';

const Carousel = () => {

    const dispatch = useDispatch();
    const [images, setImages] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [isShow, setIsShow] = useState(false);
    const isReadOnly = useSelector(state=>state.userReducer.isReadOnly);

    useEffect(() => {
        getCarousels();
    }, []);

    const getCarousels = async (condition) => {
        try {
            dispatch(loaderAction.enable());

            const res = await commonService.getCarousels(condition);
            setImages(res.response);

            dispatch(loaderAction.disable());
        } catch (err) {
            dispatch(loaderAction.disable());
            notifyUtils.error(err);
        }
    }

    const deleteCarousels = async (condition) => {
        try {
            dispatch(loaderAction.enable());

            await commonService.deleteCarousel(condition);

            notifyUtils.success("Success", "Delete Carousel successful.");

            getCarousels();

            dispatch(loaderAction.disable());
        } catch (err) {
            dispatch(loaderAction.disable());
            notifyUtils.error(err);
        }
    }

    const onDelete = (record) => {
        deleteCarousels({name:record});
    }

    const onChangeUpload = (value) => {
        if ("done" === value.status) {
            setFileList([]);
            setIsShow(false);
            getCarousels();
            notifyUtils.success("Success", "Upload Carousel successful.");
        }
    }

    const onAdd = () => {
        setIsShow(true);
    }

    const onCancel = () => {
        setIsShow(false);
    }

    const columns = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            width: 300,
            render: (_, record) => {
                return <Image width={150} src={commonUtils.getCarouselImage(record)} />
            }
        },
        {
            dataIndex: 'view',
            width: 100,
            render: (_, record) => {
                return <Popconfirm title="sure to delete?" onConfirm={() => onDelete(record)} disabled={isReadOnly}>
                            <Button type="primary" shape="circle" size="large" icon={<DeleteOutlined />} />
                        </Popconfirm>
            }
        }
    ];

    const title = () => {
        return (
            <Space>
                <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={onAdd} disabled={isReadOnly} />
            </Space>
        );
    }

    return (
        <>
            <h2 className="color-primary-title">Carousel</h2>
                <Divider className="mt-2 mb-4" />
            <Card>
                <DataTable scroll={{ x: 'max-content' }} columns={columns} dataSource={images} title={title} rowKey="id" />
            </Card>

            <Modal visible={isShow} title="Upload Carousel" onCancel={onCancel} footer={null}>
                <UploadImage action={commonService.uploadCarouselUrl} fileList={fileList} onChange={onChangeUpload} />
            </Modal>
        </>
    )
}

export default Carousel;