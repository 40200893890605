import React from 'react';
import SearchDiameter from './search';

const Diameter = () => {

    return (
        <SearchDiameter />
    )
}

export default Diameter;