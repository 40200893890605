import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Input, Button, Divider, Collapse, Select, Space } from 'antd';
import * as Icon from "react-feather";
import DataTable from '../../components/data-table';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { EditOutlined, SearchOutlined, ClearOutlined } from '@ant-design/icons';
import loaderAction from '../../redux/action/app-loader-action';
import notifyUtils from "../../utils/notify-utils";
import salesService from "../../services/sales-service";
import { ORDER_STATUS } from "../../constants/app-constants"
import orderAction from '../../redux/action/sales-action';
import formatUtils from '../../utils/format-utils';

const { Panel } = Collapse;

const SearchOrder = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const [condition, setCondition] = useState({});
    const [orders, setOrders] = useState([]);
    const isReadOnly = useSelector(state=>state.userReducer.isReadOnly);
    
    const onChangeValue = (event) => {
        const {name, value} = event.target;
        setCondition({...condition, [name]: value});
    };

    const onChangeStatus = (value) => {
        setCondition({...condition, status: value});
    };

    useEffect(() => {
        searchOrder();
    }, []);

    const searchOrder = async (condition) => {
        try {
            dispatch(loaderAction.enable());

            const res = await salesService.searchOrder(condition);
            setOrders(res.response);

            dispatch(loaderAction.disable());
        } catch (err) {
            dispatch(loaderAction.disable());
            notifyUtils.error(err);
        }
    }

    // const deleteOrder = async (condition) => {
    //     try {
    //         dispatch(loaderAction.enable());

    //         await salesService.deleteOrder(condition);

    //         onSearch();

    //         dispatch(loaderAction.disable());
    //     } catch (err) {
    //         dispatch(loaderAction.disable());
    //         notifyUtils.error(err);
    //     }
    // }

    const columns = [
        {
            title: 'Order #',
            dataIndex: 'id',
            key: 'id',
            width: 50
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 50,
            render: (_, record) => {
                return formatUtils.date(record.createdAt);
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 150
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            key: 'mobile',
            width: 150
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: 300
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            render: (_, record) => {
                return ORDER_STATUS.find(item => item.value === record.status)?.label;
            }
        },
        {
            dataIndex: 'view',
            width: 100,
            render: (_, record) => {
                return <Button type="primary" shape="circle" size="large" onClick={() => onView(record)} icon={<EditOutlined />} />
            }
        }
    ];

    const onSearch = () => {
        searchOrder(condition);
    };

    const onClear = () => {
        setCondition({});
    };

    const onView = (record) => {
        const newData = {
            isAdd: false,
            data: record,
        }
        dispatch(orderAction.order(newData));
        history.push("/order-view");
    }
    
    // const onAdd = () => {
    //     const newData = {
    //         isAdd: true,
    //         data: {
    //             name: null,
    //             status: "0",
    //         },
    //     }
    //     dispatch(orderAction.order(newData));
    //     history.push("/order-view");
    // }

    // const onDelete = (record) => {
    //     deleteOrder(record);
    // }

    const title = () => {
        return (
            <Space>
                <h5>Total Records : {orders.length}</h5>
                {/* <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={onAdd} /> */}
            </Space>
        );
    }

    return (
        <div>
            <h2 className="color-primary-title">Order</h2>
            <Divider className="mt-2 mb-4" />
            <Card className="mt-4" bordered={false}>
                <Collapse className="mb-5" defaultActiveKey={['1']}>
                    <Panel header={<> <Icon.Search className="me-2" size={14} /> Filter</>} key="1">
                        <Form layout="vertical">
                            <Row gutter={20}>
                                <Col span={12} xs={24} sm={24} md={12}>
                                    <Form.Item label="Order #:">
                                        <Input name="id" onChange={onChangeValue} value={condition.id} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} xs={24} sm={24} md={12}>
                                    <Form.Item label="Name:">
                                        <Input name="name" onChange={onChangeValue} value={condition.name} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} xs={24} sm={24} md={12}>
                                    <Form.Item label="Mobile:">
                                        <Input name="mobile" onChange={onChangeValue} value={condition.mobile} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} xs={24} sm={24} md={12}>
                                    <Form.Item label="Email:">
                                        <Input name="email" onChange={onChangeValue} value={condition.email} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} xs={24} sm={24} md={12}>
                                    <Form.Item label="Status:">
                                        <Select name="status" onChange={onChangeStatus} value={condition.status}>
                                            {ORDER_STATUS.map((item)=>{
                                                return <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col span={12} xs={24} sm={24} md={12}>
                                    <Form.Item>
                                        <Space>
                                            <Button type="primary" onClick={onSearch} icon={<SearchOutlined />}>Submit</Button>
                                            <Button onClick={onClear} icon={<ClearOutlined />}>Clear</Button>
                                        </Space>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>

                <DataTable scroll={{ x: 'max-content' }} columns={columns} dataSource={orders} title={title} rowKey="id" />
            </Card>
        </div>
    );

}

export default SearchOrder;