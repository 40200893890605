import React, { useState, useEffect } from 'react';
import { Card, Input, Button, Form, Row, Col, Space, Select, Typography } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import loaderAction from '../../redux/action/app-loader-action';
import notifyUtils from "../../utils/notify-utils";
import masterService from "../../services/master-service";
import * as Icon from "react-feather";
import { RECORD_STATUS } from "../../constants/app-constants"
import { SaveOutlined, CloseOutlined, RollbackOutlined } from '@ant-design/icons';

const ViewDiameter = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const dataSelector = useSelector(state=>state.masterReducer.diameter);
    const [data, setData] = useState(dataSelector.data);
    const [form] = Form.useForm();
    const isReadOnly = useSelector(state=>state.userReducer.isReadOnly);

    useEffect(() => {
    }, []);

    const onChangeValue = (event) => {
        const {name, value} = event.target;
        setData({...data, [name]: value});
    };

    const onChangeStatus = (value) => {
        setData({...data, status: value});
    };

    const addDiameter = async (values) => {
        try {
            dispatch(loaderAction.enable());

            await masterService.createDiameter(values);
            onBack();

            notifyUtils.success("Success", "Create Diameter successful.");

            dispatch(loaderAction.disable());
        } catch (err) {
            dispatch(loaderAction.disable());
            notifyUtils.error(err);
        }
    };

    const updateDiameter = async (values) => {
        try {
            dispatch(loaderAction.enable());

            await masterService.updateDiameter(values);
            onBack();

            notifyUtils.success("Success", "Update Diameter successful.");

            dispatch(loaderAction.disable());
        } catch (err) {
            dispatch(loaderAction.disable());
            notifyUtils.error(err);
        }
    };

    const onFinish = (values) => {
        if (dataSelector.isAdd) {
            addDiameter(values);
        }
        else {
            updateDiameter({...values, id:dataSelector.data.id});
        }
    };

    const onResetFields = () => {
        form.resetFields();
    }

    const onBack = () => {
        history.goBack(); 
    }

    return (
        <Card>
            <Row> 
                <Col span={12} xs={24} sm={24} md={12}>
                    <Typography.Title level={5}>
                        {dataSelector.isAdd ? "Add" : "Update"} Diameter
                    </Typography.Title>
                </Col>
            </Row>
            <Form name="basic" layout="vertical" className="my-3"
                form={form}
                onFinish={onFinish}
                initialValues={data}
                labelCol={{ span: 24, }} wrapperCol={{ span: 24, }}
            >
                <Form.Item
                    label={<div className="d-flex"> <Icon.Archive className="me-1 color-primary" size={18} /> Diameter Name </div>}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Please input diameter name!",
                        },
                    ]}
                >
                    <Input name="name" className="w-100" value={data.name} onChange={onChangeValue} disabled={isReadOnly} />
                </Form.Item>
                <Form.Item
                    label={<div className="d-flex"> <Icon.Settings className="me-1 color-primary" size={18} /> Status </div>}
                    name="status"
                    rules={[
                        {
                            required: true,
                            message: "Please input status!",
                        },
                    ]}
                >
                    <Select name="status" value={data.status} onChange={onChangeStatus} disabled={isReadOnly}> 
                        {RECORD_STATUS.map((item)=>{
                            return <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        span: 24,
                    }}
                >
                    <Space>
                        <Button type="primary" htmlType="submit" disabled={isReadOnly} icon={<SaveOutlined />}>Save</Button>
                        <Button type="primary" onClick={onResetFields} icon={<CloseOutlined />}>Cancel</Button>
                        <Button type="primary" onClick={onBack} icon={<RollbackOutlined />}>Back</Button>
                    </Space>
                </Form.Item>
            </Form>
        </Card>
    )

}

export default ViewDiameter;