import React, { useState } from 'react';
import { Card, Input, Button, Form, Row, Col, Space } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import loaderAction from '../../redux/action/app-loader-action';
import userAction from '../../redux/action/user-action';
import notifyUtils from "../../utils/notify-utils";
import commonService from '../../services/common-service';
import * as Icon from "react-feather";
import storageUtils from '../../utils/storage-session-utils';
import { USER_PERMISSION } from '../../constants/app-constants';
import { LoginOutlined, CloseOutlined } from '@ant-design/icons';

const Login = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [loginData, setLoginData] = useState({username:null,password:null});

    const onChangeValue = (event) => {
        const {name, value} = event.target;
        setLoginData({...loginData, [name]: value});
    };

    const login = async (values) => {
        try {
            dispatch(loaderAction.enable());

            const res = await commonService.login(values);
            dispatch(userAction.login(res.response));

            if (USER_PERMISSION.READONLY === res.response.permission) {
                dispatch(userAction.readOnly());
            }

            storageUtils.setToken(res.response.token);
            history.push("/dashboard");

            dispatch(loaderAction.disable());
        } catch (err) {
            dispatch(loaderAction.disable());
            notifyUtils.error(err);
        }
    };

    const onFinish = (values) => {
        login(values);
    }

    const onResetFields = () => {
        form.resetFields();
    }

    return (
        <Card>
            <Row> 
                <Col span={12}><h5>Login</h5></Col>
            </Row>
            <Form name="basic" layout="vertical" className="my-3"
                form={form}
                onFinish={onFinish}
                labelCol={{ span: 24, }} wrapperCol={{ span: 24, }}
            >
                <Form.Item
                    label={<div className="d-flex"> <Icon.User className="me-1 color-primary" size={18} /> Username </div>}
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: "Please input username!",
                        },
                    ]}
                >
                    <Input name="username" className="w-100" value={loginData.username} onChange={onChangeValue} />
                </Form.Item>
                <Form.Item
                    label={<div className="d-flex"> <Icon.Key className="me-1 color-primary" size={18} /> Password </div>}
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: "Please input password!",
                        },
                    ]}
                >
                    <Input.Password name="password" className="w-100" value={loginData.password} onChange={onChangeValue} />
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        span: 24,
                    }}
                >
                    <Space>
                        <Button type="primary" htmlType="submit" icon={<LoginOutlined />}>Login</Button>
                        <Button onClick={onResetFields} icon={<CloseOutlined />}>Cancel</Button>
                    </Space>
                </Form.Item>
            </Form>
        </Card>
    )
}

export default Login;