import React, { useState, useEffect } from 'react';
import { Card, Input, Button, Form, Row, Col, Space, Select, Typography } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import loaderAction from '../../redux/action/app-loader-action';
import notifyUtils from "../../utils/notify-utils";
import masterService from "../../services/master-service";
import * as Icon from "react-feather";
import { RECORD_STATUS, PRODUCT_TYPE_OPTION, PRODUCT_TYPE } from "../../constants/app-constants"
import ColorPicker from '../../components/color-picker';
import UploadImage from '../../components/upload-image';
import commonService from '../../services/common-service';
import { SaveOutlined, CloseOutlined, RollbackOutlined } from '@ant-design/icons';

const ViewProduct = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const dataSelector = useSelector(state=>state.masterReducer.product);
    const constants = useSelector(state=>state.constantsReducer.products);
    const [data, setData] = useState(dataSelector.data);
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState(dataSelector.fileList);
    const isReadOnly = useSelector(state=>state.userReducer.isReadOnly);

    useEffect(() => {
    }, []);

    const onChangeValue = (event) => {
        const {name, value} = event.target;
        setData({...data, [name]: value});
    };

    const onChangeStatus = (value) => {
        setData({...data, status: value});
    };

    const onChangeBrand = (value) => {
        setData({...data, brandId: value});
        form.setFieldsValue({modelId:null});
    };
    
    const onChangeColor = (value) => {
        setData({...data, colorId: value});
    };
    
    const onChangeModel = (value) => {
        setData({...data, modelId: value});
    };

    const onChangeDiameter = (value) => {
        setData({...data, diameterId: value});
    };

    const onChangePower = (value) => {
        setData({...data, powers: value});
    };
    
    const onChangeType = (value) => {
        setData({...data, type: value});
    };

    const onChangeUpload = (value) => {
        if (value && "done" === value.status) {
            setFileList(value);
            setData({...data, imgName: value.response.response.name});
        }
    }

    const addProduct = async (values) => {
        try {
            dispatch(loaderAction.enable());

            await masterService.createProduct(values);
            onBack();

            notifyUtils.success("Success", "Create Product successful.");

            dispatch(loaderAction.disable());
        } catch (err) {
            dispatch(loaderAction.disable());
            notifyUtils.error(err);
        }
    };

    const updateProduct = async (values) => {
        try {
            dispatch(loaderAction.enable());

            await masterService.updateProduct(values);
            onBack();

            notifyUtils.success("Success", "Update Product successful.");

            dispatch(loaderAction.disable());
        } catch (err) {
            dispatch(loaderAction.disable());
            notifyUtils.error(err);
        }
    };

    const onFinish = (values) => {
        if (dataSelector.isAdd) {
            values.imgName = values.imgName.response.response.name;
            if ("1" === values.type) {
                values.powers = values.powers.map(item => {return {powerId:item}});
            }
            addProduct(values);
        }
        else {
            values.imgName = values.imgName.response.response.name;
            if ("1" === values.type) {
                values.powers = values.powers.map(item => {return {powerId:item}});
            }
            updateProduct({...values, id:dataSelector.data.id});
        }
    };

    const onResetFields = () => {
        form.resetFields();
    }

    const onBack = () => {
        history.goBack(); 
    }

    return (
        <Card>
            <Row> 
                <Col span={12} xs={24} sm={24} md={12}>
                    <Typography.Title level={5}>
                        {dataSelector.isAdd ? "Add" : "Update"} Product
                    </Typography.Title>
                </Col>
            </Row>
            <Form name="basic" layout="vertical" className="my-3"
                form={form}
                onFinish={onFinish}
                initialValues={data}
                labelCol={{ span: 24, }} wrapperCol={{ span: 24, }}
            >
                <Form.Item
                    label={<div className="d-flex"> <Icon.Archive className="me-1 color-primary" size={18} /> Product Name </div>}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Please input product name!",
                        },
                    ]}
                >
                    <Input name="name" className="w-100" value={data.name} onChange={onChangeValue} disabled={isReadOnly} />
                </Form.Item>
                <Form.Item
                    label={<div className="d-flex"> <Icon.Clock className="me-1 color-primary" size={18} /> Type </div>}
                    name="type"
                    rules={[
                        {
                            required: true,
                            message: "Please input type!",
                        },
                    ]}
                >
                    <Select name="type" value={data.type} onChange={onChangeType} disabled={isReadOnly}>
                        {PRODUCT_TYPE_OPTION.map((item)=>{
                            return <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={<div className="d-flex"> <Icon.Gift className="me-1 color-primary" size={18} /> Brand </div>}
                    name="brandId"
                    rules={[
                        {
                            required: true,
                            message: "Please input brand!",
                        },
                    ]}
                >
                    <Select name="brandId" value={data.brandId} onChange={onChangeBrand} disabled={isReadOnly}
                        showSearch allowClear optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {constants.brands.map((item)=>{
                            return <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={<div className="d-flex"> <Icon.Moon className="me-1 color-primary" size={18} /> Model </div>}
                    name="modelId"
                    rules={[
                        {
                            required: true,
                            message: "Please input model!",
                        },
                    ]}
                >
                    <Select name="modelId" value={data.modelId} onChange={onChangeModel} disabled={isReadOnly}
                        showSearch allowClear optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {constants.models.filter(x => x.brandId === data.brandId).map((item)=>{
                            return <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={<div className="d-flex"> <Icon.CloudLightning className="me-1 color-primary" size={18} /> Color </div>}
                    name="colorId"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: "Please input color!",
                    //     },
                    // ]}
                >
                    <Select name="colorId" value={data.colorId} onChange={onChangeColor} disabled={isReadOnly}
                        showSearch allowClear optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {constants.colors.map((item)=>{
                            return <Select.Option key={item.value} value={item.value} label={item.label}><Space><ColorPicker readOnly={true} color={item.color} />{item.label}</Space></Select.Option>
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={<div className="d-flex"> <Icon.Package className="me-1 color-primary" size={18} /> Diameter </div>}
                    name="diameterId"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: "Please input diameter!",
                    //     },
                    // ]}
                >
                    <Select name="diameterId" value={data.diameterId} onChange={onChangeDiameter} disabled={isReadOnly}>
                        {constants.diameters.map((item)=>{
                            return <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                        })}
                    </Select>
                </Form.Item>
                {
                    PRODUCT_TYPE.POWER === data.type ?
                    <Form.Item
                        label={<div className="d-flex"> <Icon.Package className="me-1 color-primary" size={18} /> Power </div>}
                        name="powers"
                        rules={[
                            {
                                required: true,
                                message: "Please input power!",
                            },
                        ]}
                    >
                        <Select name="powers" value={data.powerId} onChange={onChangePower} mode="multiple" disabled={isReadOnly}
                            showSearch allowClear optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        > 
                            {constants.powers.map((item)=>{
                                return <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>
                    : null
                }
                <Form.Item
                    label={<div className="d-flex"> <Icon.Package className="me-1 color-primary" size={18} /> Image </div>}
                    name="imgName"
                    rules={[
                        {
                            required: true,
                            message: "Please upload image!",
                        },
                    ]}
                >
                    <UploadImage action={commonService.uploadProductUrl} fileList={fileList} onChange={onChangeUpload} disabled={isReadOnly} />
                </Form.Item>
                <Form.Item
                    label={<div className="d-flex"> <Icon.Settings className="me-1 color-primary" size={18} /> Status </div>}
                    name="status"
                    rules={[
                        {
                            required: true,
                            message: "Please input status!",
                        },
                    ]}
                >
                    <Select name="status" value={data.status} onChange={onChangeStatus} disabled={isReadOnly}>
                        {RECORD_STATUS.map((item)=>{
                            return <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        span: 24,
                    }}
                >
                    <Space>
                        <Button type="primary" htmlType="submit" disabled={isReadOnly} icon={<SaveOutlined />}>Save</Button>
                        <Button type="primary" onClick={onResetFields} icon={<CloseOutlined />}>Cancel</Button>
                        <Button type="primary" onClick={onBack} icon={<RollbackOutlined />}>Back</Button>
                    </Space>
                </Form.Item>
            </Form>
        </Card>
    )

}

export default ViewProduct;