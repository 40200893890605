import React from 'react'
import { HexColorPicker } from "react-colorful";

const ColorPicker = (props) => {

    const styles = {
        width: '46px',
        height: '24px',
        borderRadius: '2px',
        background: `${props.color}`,
      }

    return (
        props.readOnly ?
        <div style={styles} />
        : <HexColorPicker {...props} />
    )

}

export default ColorPicker;