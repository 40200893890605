import { notification } from 'antd';
import errorMessageHandler from '../handler/error-msg-handler';

const info = (title, message) => {
    notification['info']({
        message: title,
        description:
            message,
    });
}

const success = (title, message) => {
    notification['success']({
        message: title,
        description:
            message,
    });
}

const error = (error) => {
    const { title, message } = errorMessageHandler.getMessage(error);
    notification['error']({
        message: title,
        description:
            message,
    });
}

const warning = (title, message) => {
    notification['warning']({
        message: title,
        description:
            message,
    });
}

const notifyUtils = {
    info,
    success,
    error,
    warning,
}

export default notifyUtils;