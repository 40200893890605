import { combineReducers } from 'redux';

import appLoaderReducer from './app-loader-reducer';
import userReducer from './user-reducer';
import masterReducer from './master-reducer';
import salesReducer from './sales-reducer';
import constantsReducer from './constants-reducer';

const appReducer = combineReducers({
    appLoaderReducer,
    userReducer,
    masterReducer,
    salesReducer,
    constantsReducer,
});

export default appReducer;