import masterApi from "../api/master-api";
import { RESPONSE_STATUS } from '../constants/app-constants';

// Brand
const getBrandConstants = async (condition) => {
    const resp = await masterApi.getBrandConstants(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const searchBrand = async (condition) => {
    const resp = await masterApi.searchBrand(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const viewBrand = async (condition) => {
    const resp = await masterApi.getBrand(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const createBrand = async (condition) => {
    const resp = await masterApi.createBrand(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const updateBrand = async (condition) => {
    const resp = await masterApi.updateBrand(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const deleteBrand = async (condition) => {
    const resp = await masterApi.deleteBrand(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

// Color
const searchColor = async (condition) => {
    const resp = await masterApi.searchColor(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const viewColor = async (condition) => {
    const resp = await masterApi.getColor(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const createColor = async (condition) => {
    const resp = await masterApi.createColor(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const updateColor = async (condition) => {
    const resp = await masterApi.updateColor(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const deleteColor = async (condition) => {
    const resp = await masterApi.deleteColor(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

// Diameter
const searchDiameter = async (condition) => {
    const resp = await masterApi.searchDiameter(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const viewDiameter = async (condition) => {
    const resp = await masterApi.getDiameter(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const createDiameter = async (condition) => {
    const resp = await masterApi.createDiameter(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const updateDiameter = async (condition) => {
    const resp = await masterApi.updateDiameter(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const deleteDiameter = async (condition) => {
    const resp = await masterApi.deleteDiameter(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

// Power
const searchPower = async (condition) => {
    const resp = await masterApi.searchPower(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const viewPower = async (condition) => {
    const resp = await masterApi.getPower(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const createPower = async (condition) => {
    const resp = await masterApi.createPower(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const updatePower = async (condition) => {
    const resp = await masterApi.updatePower(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const deletePower = async (condition) => {
    const resp = await masterApi.deletePower(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

// Model
const searchModel = async (condition) => {
    const resp = await masterApi.searchModel(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const viewModel = async (condition) => {
    const resp = await masterApi.getModel(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const createModel = async (condition) => {
    const resp = await masterApi.createModel(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const updateModel = async (condition) => {
    const resp = await masterApi.updateModel(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const deleteModel = async (condition) => {
    const resp = await masterApi.deleteModel(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

// Type
const searchType = async (condition) => {
    const resp = await masterApi.searchType(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const viewType = async (condition) => {
    const resp = await masterApi.getType(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const createType = async (condition) => {
    const resp = await masterApi.createType(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const updateType = async (condition) => {
    const resp = await masterApi.updateType(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const deleteType = async (condition) => {
    const resp = await masterApi.deleteType(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

// Product
const getProductConstants = async (condition) => {
    const resp = await masterApi.getProductConstants(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const searchProduct = async (condition) => {
    const resp = await masterApi.searchProduct(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const viewProduct = async (condition) => {
    const resp = await masterApi.getProduct(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const createProduct = async (condition) => {
    const resp = await masterApi.createProduct(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const updateProduct = async (condition) => {
    const resp = await masterApi.updateProduct(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const deleteProduct = async (condition) => {
    const resp = await masterApi.deleteProduct(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

// Product Powers
const getProductPowers = async (condition) => {
    const resp = await masterApi.getProductPowers(condition);
    if (RESPONSE_STATUS.SUCCESS !== resp.status) {
        throw resp.errorMessage;
    }
    return resp;
}

const masterService = {
    getBrandConstants,
    searchBrand,
    viewBrand,
    createBrand,
    updateBrand,
    deleteBrand,
    searchColor,
    viewColor,
    createColor,
    updateColor,
    deleteColor,
    searchDiameter,
    viewDiameter,
    createDiameter,
    updateDiameter,
    deleteDiameter,
    searchPower,
    viewPower,
    createPower,
    updatePower,
    deletePower,
    searchModel,
    viewModel,
    createModel,
    updateModel,
    deleteModel,
    searchType,
    viewType,
    createType,
    updateType,
    deleteType,
    getProductConstants,
    searchProduct,
    viewProduct,
    createProduct,
    updateProduct,
    deleteProduct,
    getProductPowers,
}

export default masterService;