import React from 'react';
import SearchPower from './search';

const Power = () => {

    return (
        <SearchPower />
    )
}

export default Power;